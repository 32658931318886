import { InvoiceData } from "../types";
import { format } from "date-fns";
import { SupabaseClient } from "@supabase/supabase-js";

type InvoiceSettings = {
  default_font?: string;
  default_header_color?: string;
  default_header_text_color?: string;
  default_background_color?: string;
  default_body_text_color?: string;
  default_tax_rate?: number;
};

// Update the Client type to match the structure in InvoiceData
type Client = {
  name: string;
  company: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
};

function getFormattedAddress(client: Client | undefined) {
  if (!client) return "";
  const addressParts = [
    client.address_line1,
    client.address_line2,
    client.city,
    client.state,
    client.postal_code,
    client.country,
  ].filter(Boolean);
  return addressParts.join(", ");
}

export function generateInvoiceHTML(
  invoice: InvoiceData,
  baseUrl: string
): string {
  const totals = calculateTotals(invoice);
  const webFonts = {
    Inter: "https://fonts.googleapis.com/css2?family=Inter&display=swap",
    Montserrat:
      "https://fonts.googleapis.com/css2?family=Montserrat&display=swap",
    "IBM Plex Mono":
      "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap",
    Merriweather:
      "https://fonts.googleapis.com/css2?family=Merriweather&display=swap",
    "Playfair Display":
      "https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap",
    Lora: "https://fonts.googleapis.com/css2?family=Lora&display=swap",
    Barlow: "https://fonts.googleapis.com/css2?family=Barlow&display=swap",
    "PT Serif":
      "https://fonts.googleapis.com/css2?family=PT+Serif&display=swap",
    "Noto Serif":
      "https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap",
    "Space Grotesk":
      "https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap",
  };

  const fontFamily = invoice.font as keyof typeof webFonts;
  const fontUrl = webFonts[fontFamily] || webFonts["Inter"];
  const fontName = fontFamily || "Inter";
  const texturePath = `${baseUrl}/assets/images/Texture.png`;

  let headerHTML = "";
  switch (invoice.invoice_template) {
    case "modern":
      headerHTML = `
        <div class="header modern-header">
          <div class="header-content">
            <h1>Invoice ${invoice.invoice_number || ""}</h1>
            <div class="header-details">
              <p>Status: ${invoice.status}</p>
              <p>Date: ${format(
                new Date(invoice.invoice_date ?? new Date()),
                "yyyy-MM-dd"
              )}</p>
              <p>Due Date: ${format(
                new Date(invoice.due_date ?? new Date()),
                "yyyy-MM-dd"
              )}</p>
            </div>
          </div>
          <svg class="header-image" width="184" height="37" viewBox="0 0 184 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M170.869 17.232C168.661 17.232 167.365 18.528 167.365 21.792H174.181C174.133 17.952 172.885 17.232 170.869 17.232ZM183.877 25.296C183.877 32.352 180.757 36.48 171.781 36.48C162.325 36.48 156.757 32.88 156.757 22.704C156.757 13.248 161.269 9.36 170.965 9.36C180.949 9.36 183.925 14.016 183.925 20.064C183.925 21.216 183.925 22.464 183.877 23.664C182.149 23.712 172.069 23.664 167.461 23.664C167.941 26.832 169.909 27.696 173.557 27.696C177.685 27.696 178.837 26.784 178.741 25.296H183.877Z" fill="currentColor"/>
            <path d="M140.521 19.008C138.841 19.008 138.025 20.112 138.025 22.032C138.025 25.488 140.088 26.448 144.072 26.448C146.472 26.448 148.633 25.968 148.777 23.376H154.105C154.105 31.296 151.129 36.48 141.865 36.48C133.513 36.48 127.368 33.456 127.368 22.704C127.368 15.936 129.577 9.36 136.249 9.36C140.425 9.36 142.68 11.184 143.544 19.776H144.984V9.84H154.057V21.504H142.872C142.44 19.536 141.577 19.008 140.521 19.008Z" fill="currentColor"/>
            <path d="M117.834 0.192001C122.49 0.192001 124.938 1.392 124.938 4.272C124.938 7.248 122.49 8.352 117.834 8.352C113.178 8.352 110.778 7.248 110.778 4.272C110.778 1.344 113.178 0.192001 117.834 0.192001ZM123.594 9.84V23.808C123.594 25.824 123.93 26.688 125.61 26.688V36H110.778V26.688C112.65 26.688 112.938 25.824 112.938 23.808V21.984C112.938 19.776 112.65 19.104 110.778 19.104V9.84H123.594Z" fill="currentColor"/>
            <path d="M108.623 22.704C108.623 32.064 104.639 36.48 94.5106 36.48C84.3346 36.48 80.3506 32.112 80.3506 22.704C80.3506 13.44 84.3346 9.36 94.5106 9.36C104.639 9.36 108.623 13.584 108.623 22.704ZM98.2546 22.656C98.2546 18.96 97.3906 17.664 94.5106 17.664C91.5826 17.664 90.7186 18.96 90.7186 22.656C90.7186 26.4 91.5826 28.128 94.5106 28.128C97.3906 28.128 98.2546 26.4 98.2546 22.656Z" fill="currentColor"/>
            <path d="M74.3724 36H57.2844L54.5964 21.84C54.2124 19.968 53.8284 19.056 52.9644 19.056V9.84H68.1804V19.056C66.4044 19.44 65.6364 20.16 65.6364 21.408C65.6364 22.56 66.0684 23.424 68.0844 25.104L73.5084 29.616C74.4204 22.992 73.3164 20.832 69.9564 19.056V9.84H78.9324V19.152C77.0124 21.408 75.9564 26.928 74.3724 36Z" fill="currentColor"/>
            <path d="M36.2563 26.688V36H21.7122V26.688C23.5842 26.688 23.8722 25.824 23.8722 23.808V22.56C23.8722 19.92 23.5362 19.104 21.7122 19.104V9.84H32.6562V19.344H33.8083C34.2403 11.136 36.6402 9.36 42.4482 9.36C48.3043 9.36 50.0803 12.048 50.0803 18.384V23.808C50.0803 25.824 50.3203 26.688 51.7603 26.688V36H37.7923V26.688C39.3283 26.688 39.7122 25.824 39.7122 23.808V22.272C39.7122 19.68 38.9923 19.104 37.0243 19.104C35.3922 19.104 34.0962 19.776 34.0962 21.936V23.808C34.0962 25.824 34.7203 26.688 36.2563 26.688Z" fill="currentColor"/>
            <path d="M3.65599 23.472V18.24C3.65599 15.552 3.60799 15.408 0.343994 15.408V5.75999H19.352V15.408C16.04 15.408 15.992 15.552 15.992 18.24V23.472C15.992 26.16 16.04 26.304 19.352 26.304V36H0.343994V26.304C3.60799 26.304 3.65599 26.16 3.65599 23.472Z" fill="currentColor"/>
          </svg>
        </div>
      `;
      break;
    case "detailed":
      headerHTML = `
          <div style="
            font-family: ${invoice.font || "Arial, sans-serif"};
            padding: 80px;
            background-color: ${invoice.header_color || "#f8f8f8"};
            background-image: url('${baseUrl}/assets/images/Texture.png');
            color: ${invoice.header_text_color || "inherit"};
          ">
            <div style="display: flex; justify-content: space-between; align-items: stretch;">
              <div style="display: flex; flex-direction: column; justify-content: space-between;">
                ${
                  invoice.logo_url
                    ? `<img src="${invoice.logo_url}" alt="Company Logo" style="max-width: 200px; width: fit-content; max-height: 100px; object-fit: contain; margin-bottom: 20px;">`
                    : ""
                }
                <h1 style="font-size: 3rem; font-weight: normal; margin: 0 0 20px 0;">INVOICE</h1>
                <div style="margin-top: 2rem;">
                  <h3 style="font-size: 14px; opacity: 0.66; font-weight: normal; margin: 0 0 4px 0;">Billed to</h3>
                  <div style="font-size: 0.9rem;">
                    ${invoice.client?.name || "N/A"}
                  </div>
                  <div style="font-size: 0.9rem; margin-top: 4px;">
                    ${invoice.client?.company || ""}
                    ${getFormattedAddress(invoice.client)}
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-direction: column; align-items: flex-end;">
                <div style="text-align: right; display: flex; flex-direction: column; justify-content: space-between; gap: 48px;">
                  <div style="margin-bottom: 1.5rem;">
                    <div style="font-size: 0.9rem; opacity: 0.66;">Amount Due (${
                      invoice.currency
                    })</div>
                    <div style="font-size: 2rem; font-weight: bold;">$${totals.remainingBalance.toFixed(
                      2
                    )}</div>
                  </div>
                  <div>
                    <div style="margin-bottom: 0.5rem; font-size: 0.9rem; display: flex; justify-content: space-between; min-width: 256px;">
                      <span style="opacity: 0.66; margin-right: 0.5rem;">Invoice Date:</span>
                      <span>${format(
                        new Date(invoice.invoice_date || new Date()),
                        "yyyy-MM-dd"
                      )}</span>
                    </div>
                    <div style="margin-bottom: 0.5rem; font-size: 0.9rem; display: flex; justify-content: space-between; min-width: 256px;">
                      <span style="opacity: 0.66; margin-right: 0.5rem;">Due Date:</span>
                      <span>${format(
                        new Date(invoice.due_date || new Date()),
                        "yyyy-MM-dd"
                      )}</span>
                    </div>
                    <div style="margin-bottom: 0.5rem; font-size: 0.9rem; display: flex; justify-content: space-between; min-width: 256px;">
                      <span style="opacity: 0.66; margin-right: 0.5rem;">Invoice #:</span>
                      <span>${invoice.invoice_number || "N/A"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `;
      break;
    case "simple":
      headerHTML = `
        <div class="header classic-header">
          <svg class="header-image" width="184" height="37" viewBox="0 0 184 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M170.869 17.232C168.661 17.232 167.365 18.528 167.365 21.792H174.181C174.133 17.952 172.885 17.232 170.869 17.232ZM183.877 25.296C183.877 32.352 180.757 36.48 171.781 36.48C162.325 36.48 156.757 32.88 156.757 22.704C156.757 13.248 161.269 9.36 170.965 9.36C180.949 9.36 183.925 14.016 183.925 20.064C183.925 21.216 183.925 22.464 183.877 23.664C182.149 23.712 172.069 23.664 167.461 23.664C167.941 26.832 169.909 27.696 173.557 27.696C177.685 27.696 178.837 26.784 178.741 25.296H183.877Z" fill="currentColor"/>
            <path d="M140.521 19.008C138.841 19.008 138.025 20.112 138.025 22.032C138.025 25.488 140.088 26.448 144.072 26.448C146.472 26.448 148.633 25.968 148.777 23.376H154.105C154.105 31.296 151.129 36.48 141.865 36.48C133.513 36.48 127.368 33.456 127.368 22.704C127.368 15.936 129.577 9.36 136.249 9.36C140.425 9.36 142.68 11.184 143.544 19.776H144.984V9.84H154.057V21.504H142.872C142.44 19.536 141.577 19.008 140.521 19.008Z" fill="currentColor"/>
            <path d="M117.834 0.192001C122.49 0.192001 124.938 1.392 124.938 4.272C124.938 7.248 122.49 8.352 117.834 8.352C113.178 8.352 110.778 7.248 110.778 4.272C110.778 1.344 113.178 0.192001 117.834 0.192001ZM123.594 9.84V23.808C123.594 25.824 123.93 26.688 125.61 26.688V36H110.778V26.688C112.65 26.688 112.938 25.824 112.938 23.808V21.984C112.938 19.776 112.65 19.104 110.778 19.104V9.84H123.594Z" fill="currentColor"/>
            <path d="M108.623 22.704C108.623 32.064 104.639 36.48 94.5106 36.48C84.3346 36.48 80.3506 32.112 80.3506 22.704C80.3506 13.44 84.3346 9.36 94.5106 9.36C104.639 9.36 108.623 13.584 108.623 22.704ZM98.2546 22.656C98.2546 18.96 97.3906 17.664 94.5106 17.664C91.5826 17.664 90.7186 18.96 90.7186 22.656C90.7186 26.4 91.5826 28.128 94.5106 28.128C97.3906 28.128 98.2546 26.4 98.2546 22.656Z" fill="currentColor"/>
            <path d="M74.3724 36H57.2844L54.5964 21.84C54.2124 19.968 53.8284 19.056 52.9644 19.056V9.84H68.1804V19.056C66.4044 19.44 65.6364 20.16 65.6364 21.408C65.6364 22.56 66.0684 23.424 68.0844 25.104L73.5084 29.616C74.4204 22.992 73.3164 20.832 69.9564 19.056V9.84H78.9324V19.152C77.0124 21.408 75.9564 26.928 74.3724 36Z" fill="currentColor"/>
            <path d="M36.2563 26.688V36H21.7122V26.688C23.5842 26.688 23.8722 25.824 23.8722 23.808V22.56C23.8722 19.92 23.5362 19.104 21.7122 19.104V9.84H32.6562V19.344H33.8083C34.2403 11.136 36.6402 9.36 42.4482 9.36C48.3043 9.36 50.0803 12.048 50.0803 18.384V23.808C50.0803 25.824 50.3203 26.688 51.7603 26.688V36H37.7923V26.688C39.3283 26.688 39.7122 25.824 39.7122 23.808V22.272C39.7122 19.68 38.9923 19.104 37.0243 19.104C35.3922 19.104 34.0962 19.776 34.0962 21.936V23.808C34.0962 25.824 34.7203 26.688 36.2563 26.688Z" fill="currentColor"/>
            <path d="M3.65599 23.472V18.24C3.65599 15.552 3.60799 15.408 0.343994 15.408V5.75999H19.352V15.408C16.04 15.408 15.992 15.552 15.992 18.24V23.472C15.992 26.16 16.04 26.304 19.352 26.304V36H0.343994V26.304C3.60799 26.304 3.65599 26.16 3.65599 23.472Z" fill="currentColor"/>
          </svg>
          <div class="header-content">
            <h1>Invoice ${invoice.invoice_number || ""}</h1>
            <table class="header-details">
              <tr><td>Status:</td><td>${invoice.status}</td></tr>
              <tr><td>Date:</td><td>${format(
                new Date(invoice.invoice_date ?? new Date()),
                "yyyy-MM-dd"
              )}</td></tr>
              <tr><td>Due Date:</td><td>${format(
                new Date(invoice.due_date ?? new Date()),
                "yyyy-MM-dd"
              )}</td></tr>
            </table>
          </div>
        </div>
      `;
      break;
    case "boxed":
      headerHTML = `
          <div class="boxed-header" style="
            font-family: ${invoice.font || "Arial, sans-serif"};
            background-color: ${invoice.header_color || "#f8f8f8"};
            padding: 30px;
            border: 2px solid ${invoice.header_color || "#f8f8f8"};
            box-shadow: 0 0 0 10px ${invoice.header_color || "#f8f8f8"};
            margin: 20px;
            color: ${invoice.header_text_color || "inherit"};
          ">
            <div class="header-content" style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 20px;
            ">
              ${
                invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                max-width: 150px;
                max-height: 75px;
                object-fit: contain;
                grid-column: 1 / -1;
                margin-bottom: 20px;
              ">`
                  : ""
              }
              <h1 style="
                font-size: 2.5rem;
                font-weight: bold;
                margin: 0;
                grid-column: 1 / -1;
                border-bottom: 2px solid currentColor;
                padding-bottom: 10px;
              ">Invoice</h1>
              <div class="detail-column" style="display: flex; flex-direction: column;">
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                 <strong>Client:</strong> ${invoice.client?.name || "N/A"}
                </div>
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                  <strong>Invoice Date:</strong> ${format(
                    new Date(invoice.invoice_date || new Date()),
                    "yyyy-MM-dd"
                  )}
                </div>
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                  <strong>Due Date:</strong> ${format(
                    new Date(invoice.due_date || new Date()),
                    "yyyy-MM-dd"
                  )}
                </div>
              </div>
              <div class="detail-column" style="display: flex; flex-direction: column;">
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                  <strong>Invoice #:</strong> ${invoice.invoice_number || "N/A"}
                </div>
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                  <strong>Amount Due:</strong> $${totals.remainingBalance.toFixed(
                    2
                  )}
                </div>
              </div>
            </div>
          </div>
        `;
      break;

    case "gradient":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Arial, sans-serif"};
          padding: 50px;
          border-radius: 0 0 30px 30px;
          background: linear-gradient(135deg, ${
            invoice.header_color || "#f8f8f8"
          }, ${invoice.header_color || "#f8f8f8"}aa);
          color: ${invoice.header_text_color || "inherit"};
        ">
          <div style="display: flex; flex-direction: column; align-items: center;">
            ${
              invoice.logo_url
                ? `<img src="${invoice.logo_url}" alt="Company Logo" style="max-width: 200px; max-height: 100px; object-fit: contain; margin-bottom: 20px;">`
                : ""
            }
            <h1 style="font-size: 3rem; font-weight: bold; margin: 0 0 20px 0; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);">Invoice</h1>
            <div style="font-size: 1.2rem; margin-bottom: 20px;">${
              invoice.client?.name || "N/A"
            }</div>
            <div style="display: flex; justify-content: space-between; width: 100%; margin-top: 20px;">
              <div style="display: flex; flex-direction: column; align-items: flex-start;">
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                  <span>Invoice Date: ${format(
                    new Date(invoice.invoice_date || new Date()),
                    "yyyy-MM-dd"
                  )}</span>
                </div>
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                  <span>Due Date: ${format(
                    new Date(invoice.due_date || new Date()),
                    "yyyy-MM-dd"
                  )}</span>
                </div>
              </div>
              <div style="display: flex; flex-direction: column; align-items: flex-end;">
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                  <span>Invoice #: ${invoice.invoice_number || "N/A"}</span>
                </div>
                <div style="margin-bottom: 0.5rem; font-size: 0.9rem;">
                  <span>Amount Due: $${totals.remainingBalance.toFixed(
                    2
                  )}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;
      break;
    default: // 'simple' or any other value
      headerHTML = `
        <div class="header simple-header">
          <svg class="header-image" width="184" height="37" viewBox="0 0 184 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M170.869 17.232C168.661 17.232 167.365 18.528 167.365 21.792H174.181C174.133 17.952 172.885 17.232 170.869 17.232ZM183.877 25.296C183.877 32.352 180.757 36.48 171.781 36.48C162.325 36.48 156.757 32.88 156.757 22.704C156.757 13.248 161.269 9.36 170.965 9.36C180.949 9.36 183.925 14.016 183.925 20.064C183.925 21.216 183.925 22.464 183.877 23.664C182.149 23.712 172.069 23.664 167.461 23.664C167.941 26.832 169.909 27.696 173.557 27.696C177.685 27.696 178.837 26.784 178.741 25.296H183.877Z" fill="currentColor"/>
            <path d="M140.521 19.008C138.841 19.008 138.025 20.112 138.025 22.032C138.025 25.488 140.088 26.448 144.072 26.448C146.472 26.448 148.633 25.968 148.777 23.376H154.105C154.105 31.296 151.129 36.48 141.865 36.48C133.513 36.48 127.368 33.456 127.368 22.704C127.368 15.936 129.577 9.36 136.249 9.36C140.425 9.36 142.68 11.184 143.544 19.776H144.984V9.84H154.057V21.504H142.872C142.44 19.536 141.577 19.008 140.521 19.008Z" fill="currentColor"/>
            <path d="M117.834 0.192001C122.49 0.192001 124.938 1.392 124.938 4.272C124.938 7.248 122.49 8.352 117.834 8.352C113.178 8.352 110.778 7.248 110.778 4.272C110.778 1.344 113.178 0.192001 117.834 0.192001ZM123.594 9.84V23.808C123.594 25.824 123.93 26.688 125.61 26.688V36H110.778V26.688C112.65 26.688 112.938 25.824 112.938 23.808V21.984C112.938 19.776 112.65 19.104 110.778 19.104V9.84H123.594Z" fill="currentColor"/>
            <path d="M108.623 22.704C108.623 32.064 104.639 36.48 94.5106 36.48C84.3346 36.48 80.3506 32.112 80.3506 22.704C80.3506 13.44 84.3346 9.36 94.5106 9.36C104.639 9.36 108.623 13.584 108.623 22.704ZM98.2546 22.656C98.2546 18.96 97.3906 17.664 94.5106 17.664C91.5826 17.664 90.7186 18.96 90.7186 22.656C90.7186 26.4 91.5826 28.128 94.5106 28.128C97.3906 28.128 98.2546 26.4 98.2546 22.656Z" fill="currentColor"/>
            <path d="M74.3724 36H57.2844L54.5964 21.84C54.2124 19.968 53.8284 19.056 52.9644 19.056V9.84H68.1804V19.056C66.4044 19.44 65.6364 20.16 65.6364 21.408C65.6364 22.56 66.0684 23.424 68.0844 25.104L73.5084 29.616C74.4204 22.992 73.3164 20.832 69.9564 19.056V9.84H78.9324V19.152C77.0124 21.408 75.9564 26.928 74.3724 36Z" fill="currentColor"/>
            <path d="M36.2563 26.688V36H21.7122V26.688C23.5842 26.688 23.8722 25.824 23.8722 23.808V22.56C23.8722 19.92 23.5362 19.104 21.7122 19.104V9.84H32.6562V19.344H33.8083C34.2403 11.136 36.6402 9.36 42.4482 9.36C48.3043 9.36 50.0803 12.048 50.0803 18.384V23.808C50.0803 25.824 50.3203 26.688 51.7603 26.688V36H37.7923V26.688C39.3283 26.688 39.7122 25.824 39.7122 23.808V22.272C39.7122 19.68 38.9923 19.104 37.0243 19.104C35.3922 19.104 34.0962 19.776 34.0962 21.936V23.808C34.0962 25.824 34.7203 26.688 36.2563 26.688Z" fill="currentColor"/>
            <path d="M3.65599 23.472V18.24C3.65599 15.552 3.60799 15.408 0.343994 15.408V5.75999H19.352V15.408C16.04 15.408 15.992 15.552 15.992 18.24V23.472C15.992 26.16 16.04 26.304 19.352 26.304V36H0.343994V26.304C3.60799 26.304 3.65599 26.16 3.65599 23.472Z" fill="currentColor"/>
          </svg>
          <h1>Invoice ${invoice.invoice_number || ""}</h1>
          <p>Status: ${invoice.status}</p>
          <p>Date: ${format(
            new Date(invoice.invoice_date ?? new Date()),
            "yyyy-MM-dd"
          )}</p>
          <p>Due Date: ${format(
            new Date(invoice.due_date ?? new Date()),
            "yyyy-MM-dd"
          )}</p>
        </div>
      `;
  }

  return `
    <html>
      <head>
        <link href="${fontUrl}" rel="stylesheet">
        <style>
          html, body {
            margin: 0;
            padding: 0;
            height: 100%;
          }
          body {
            font-family: '${fontName}', sans-serif;
            background-color: ${invoice.background_color || "#ffffff"};
            color: ${invoice.body_text_color || "#000000"};
            display: flex;
            flex-direction: column;
            min-height: 100vh;

          }
          .header {
            background: ${invoice.header_color || "#ffffff"};
            color: ${invoice.header_text_color || "#000000"};
            padding: 20px;
            margin-bottom: 20px;
            background-image: url('${texturePath}');
          }
          .invoice-items {
            padding: 20px;
            margin-top: 20px;
            background: transparent;
          }
          .invoice-item-header {
            font-weight: bold;
            margin-bottom: 10px;
            display: grid;
            grid-template-columns: 5fr 1fr 1fr 1fr 1fr;
            gap: 10px;
          }
          .invoice-item-header-cell {
            font-size: 14px;
          }
          .invoice-item {
            display: grid;
            grid-template-columns: 5fr 1fr 1fr 1fr 1fr;
            gap: 10px;
            align-items: center;
            background-color: transparent;
            padding: 10px 0;
          }
          .invoice-item-field {
            font-size: 16px;
          }
          .text-right {
            text-align: right;
          }
          .text-center {
            text-align: center;
          }
          .total-container {
            display: flex;
            justify-content: flex-end;
          }
          .invoice-total {
            width: fit-content;
            min-width: 200px;
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
          .divider {
            width: 100%;
            border: none;
            border-top: 1px solid rgba(0,0,0,0.1);
            margin: 8px 0;
          }
          .total-item {
            display: flex;
            justify-content: space-between;
          }
          .notes {
            padding: 20px;
            margin-top: 20px;
            background: transparent;
            font-family: inherit;
          }
          .notes h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }
          .notes p {
            font-size: 14px;
            white-space: pre-wrap;
          }
          .header-image {
            width: 184px;
            height: 37px;
          }
        </style>
      </head>
      <body>
        ${headerHTML} 
        <div class="invoice-items">
          <div class="invoice-item-header">
            <div class="invoice-item-header-cell">Description</div>
            <div class="invoice-item-header-cell">Quantity</div>
            <div class="invoice-item-header-cell">Unit Price</div>
            <div class="invoice-item-header-cell text-right">Amount</div>
            <div class="invoice-item-header-cell text-center">Taxable</div>
          </div>
          ${invoice.items
            .map(
              (item) => `
            <div class="invoice-item">
              <div class="invoice-item-field">  ${item.description}</div>
              <div class="invoice-item-field">${item.quantity}</div>
              <div class="invoice-item-field">$${item.price.toFixed(2)}</div>
              <div class="invoice-item-field text-right">$${(
                item.quantity * item.price
              ).toFixed(2)}</div>
              <div class="invoice-item-field text-center">${
                item.taxable ? "✓" : "✗"
              }</div>
            </div>
          `
            )
            .join("")}
        </div>

        <div class="total-container">
          <div class="invoice-total">
            <div class="total-item"><span>Subtotal:</span> <span>$${totals.subtotal.toFixed(
              2
            )}</span></div>
            <div class="total-item"><span>Tax (${
              invoice.tax_rate
            }%):</span> <span>$${totals.taxAmount.toFixed(2)}</span></div>
            <div class="total-item"><span>Total Paid:</span> <span>$${totals.totalPaid.toFixed(
              2
            )}</span></div>
            <hr class="divider" />
            <div class="total-item"><span>Amount Due:</span> <span>$${totals.remainingBalance.toFixed(
              2
            )}</span></div>
          </div>
        </div>

        ${
          invoice.notes
            ? `
        <div class="notes">
          <h2>Notes</h2>
          <p>${invoice.notes}</p>
        </div>
        `
            : ""
        }
      </body>
    </html>
  `;
}

export function calculateTotals(invoice: InvoiceData) {
  const subtotal = invoice.items.reduce(
    (sum, item) => sum + item.quantity * item.price,
    0
  );
  const taxableAmount = invoice.items
    .filter((item) => item.taxable)
    .reduce((sum, item) => sum + item.quantity * item.price, 0);
  const taxAmount = taxableAmount * (invoice.tax_rate / 100);
  const total = subtotal + taxAmount;
  const totalPaid = invoice.payments.reduce(
    (sum, payment) => sum + payment.amount,
    0
  );
  const remainingBalance = total - totalPaid;
  return { subtotal, taxAmount, total, totalPaid, remainingBalance };
}

// Update the function signature
export function mergeInvoiceData(
  invoiceData: InvoiceData,
  brandSettings: InvoiceSettings,
  defaultSettings: InvoiceSettings
) {
  return {
    ...invoiceData,
    font:
      invoiceData.font ||
      brandSettings.default_font ||
      defaultSettings.default_font,
    header_color:
      invoiceData.header_color ||
      brandSettings.default_header_color ||
      defaultSettings.default_header_color,
    header_text_color:
      invoiceData.header_text_color ||
      brandSettings.default_header_text_color ||
      defaultSettings.default_header_text_color,
    background_color:
      invoiceData.background_color ||
      brandSettings.default_background_color ||
      defaultSettings.default_background_color,
    body_text_color:
      invoiceData.body_text_color ||
      brandSettings.default_body_text_color ||
      defaultSettings.default_body_text_color,
    tax_rate:
      invoiceData.tax_rate ??
      brandSettings.default_tax_rate ??
      defaultSettings.default_tax_rate,
  };
}

// Update the defaultSettings object to use the new type
export const defaultSettings: InvoiceSettings = {
  default_font: "Inter, sans-serif",
  default_header_color: "#ffffff",
  default_header_text_color: "#000000",
  default_background_color: "#ffffff",
  default_body_text_color: "#000000",
  default_tax_rate: 0,
};

export async function verifyToken(supabase: SupabaseClient, token: string) {
  const {
    data: { user },
    error,
  } = await supabase.auth.getUser(token);
  if (error || !user) {
    throw new Error("Invalid Token");
  }
  return user;
}
