import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Payment, InvoiceData, Client } from '../../types';
import { useNavigate } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import Button from '../Button';
import { Calendar12, Client12, DueDate12, Money12, Number12, Status12, Invoice12, Eye12, Copy12 } from '../Icon';
import { StatusBadge } from '../StatusBadge';
import { format, isThisYear } from 'date-fns';

const DetailsContainer = styled.div<{ $status: string }>`
  display: flex;
  flex-direction: column;
`;

const GridSection = styled.div`
  display: grid;
  row-gap: 16px;
  column-gap: 24px;
  padding: 32px 40px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  grid-template-columns: max-content 1fr;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const DetailValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
`;

const ShareLinkContainer = styled.div`
  padding: 32px 40px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ShareLink = styled.div`
  color: rgba(0,0,0,0.5);
  word-break: break-all;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ShareLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 4px;
`;

const ShareLinkButton = styled(Button)`
  background-color: rgba(255,255,255,1);
  box-shadow: 0 1px 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const TitleAmount = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
`;

const OpenInvoiceButton = styled(Button)`
  margin-bottom: 16px;
  padding: 8px 12px;
  text-align: center;
  justify-content: center;
  width: 100%;
  box-shadow: inset 0 0.5px 0 rgba(255, 255, 255, 0.75), 0 0.5px 0px rgba(0,0,0,0.15);
  transition: transform 0.1s ease-in-out;

  &:active {
    transform: scale(0.995);
  }
`;

const InvoiceStatus = styled(StatusBadge)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 4px 10px;
  border-radius: 6px;
  width: fit-content;
  border: 2px solid;
  background: transparent;
  border-style: ${({ $status }) => $status === 'draft' ? 'dashed' : 'solid'};
  border-color: ${({ $status }) => {
    switch ($status) {
      case 'paid':
        return '#1e7e34';
      case 'unpaid':
        return '#f29d41';
      case 'overdue':
        return '#d73a49';
      case 'draft':
        return '#495057';
      default:
        return '#495057';
    }
  }};
`;

const TitleSection = styled.div`
  padding: 40px 40px 24px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const HistorySection = styled.div`
  padding: 32px 40px;
`;

const HistoryItem = styled.div`
  margin-bottom: 16px;
`;

const HistoryIcon = styled.div`
  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const HistoryDate = styled.div<{ $isLast: boolean }>`
  font-size: 14px;
  border-left: 1px solid ${props => props.$isLast ? 'transparent' : 'rgba(0,0,0,0.1)'};
  padding: 0 0 24px 22px;
  margin-left: 6px;
  color: rgba(0,0,0,0.5);
`;

const HistoryEvent = styled.div`
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const SectionHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
  color: rgba(0,0,0,0.5);
`;

interface InvoiceDetailsProps {
  invoice: InvoiceData;
  clients: Client[];
  navigate: ReturnType<typeof useNavigate>;
  queryClient: ReturnType<typeof useQueryClient>;
  payments: Payment[];
  emailTrackingData?: {
    open_count: number;
    open_history: string[];
  } | null;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ invoice, clients, navigate, payments, emailTrackingData }) => {
  const [copyButtonText, setCopyButtonText] = useState('Copy Link');

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const getClientName = useMemo(() => {
    const client = clients.find(client => client.id === invoice?.client_id);
    return client ? client.full_name : 'No Client';
  }, [clients, invoice?.client_id]);


  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const isCurrentYear = isThisYear(date);
    return isCurrentYear
      ? format(date, 'MMM d \'at\' h:mma')
      : format(date, 'MMM d, yyyy \'at\' h:mma');
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: invoice.currency || 'USD' }).format(amount);
  };

  const historyItems = useMemo(() => {
    const creationItem = {
      date: new Date(invoice.invoice_created_at || invoice.invoice_date || new Date()),
      event: 'Invoice created',
      type: 'creation'
    };

    const otherItems = [
      ...(payments || [])
        .filter(payment => payment.invoice_id === invoice.id)
        .map(payment => ({
          date: new Date(payment.payment_date),
          event: `Payment received: ${formatCurrency(payment.amount)}`,
          type: 'payment'
        })),
      ...(emailTrackingData?.open_history || []).map(openEvent => ({
        date: new Date(openEvent),
        event: 'Invoice viewed',
        type: 'view'
      }))
    ];

    // Sort other items by timestamp (oldest first)
    otherItems.sort((a, b) => {
      const timeA = a.date.getTime();
      const timeB = b.date.getTime();
      if (isNaN(timeA) && isNaN(timeB)) return 0;
      if (isNaN(timeA)) return 1;
      if (isNaN(timeB)) return -1;
      return timeA - timeB;
    });

    // Combine creation item with other sorted items
    return [creationItem, ...otherItems];
  }, [invoice.id, invoice.invoice_created_at, invoice.invoice_date, payments, emailTrackingData, formatCurrency]);

  const getIconForType = (type: string) => {
    switch (type) {
      case 'creation':
        return <Invoice12 />;
      case 'payment':
        return <Money12 />;
      case 'view':
        return <Eye12 />;
      default:
        return null;
    }
  };

  if (!invoice) return <div>No invoice data available</div>;

  // Use the total amount instead of amount_due
  const totalAmount = invoice.total || 0;
  const amountDue = invoice.amount_due || 0;

  console.log('InvoiceDetails - Received invoice:', invoice);
  console.log('InvoiceDetails - Total amount:', totalAmount);

  // Calculate days until due date or days overdue
  const today = new Date();
  const dueDate = new Date(invoice.due_date);
  const daysDifference = Math.ceil((dueDate.getTime() - today.getTime()) / (1000 * 3600 * 24));

  // Format the total amount
  const formattedAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: invoice.currency || 'USD' }).format(totalAmount);

  console.log('InvoiceDetails - Formatted amount:', formattedAmount);

  // Format the amount due
  const formattedAmountDue = new Intl.NumberFormat('en-US', { style: 'currency', currency: invoice.currency || 'USD' }).format(amountDue);

  // Construct the share link
  const shareLink = `${window.location.origin}/share/${invoice.public_id}`;

  const handleOpenInvoice = () => {
    if (invoice) {
      navigate({ to: '/invoice/$id', params: { id: invoice.id } });
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setCopyButtonText('Copied');
    setTimeout(() => setCopyButtonText('Copy'), 2000);
  };

  return (
    <DetailsContainer $status={invoice.status}>
      <TitleSection>
        <InvoiceStatus $status={invoice.status}>
          {invoice.status}
        </InvoiceStatus>
        <Title>
          <TitleAmount>Invoice for {formattedAmount}</TitleAmount>
          <TitleAmount>
            {invoice.status === 'paid'
              ? 'is all paid for'
              : daysDifference > 0
              ? `is due in ${daysDifference} ${daysDifference === 1 ? 'day' : 'days'}`
              : daysDifference === 0
              ? 'is due today'
              : `was due ${Math.abs(daysDifference)} ${Math.abs(daysDifference) === 1 ? 'day' : 'days'} ago`}
          </TitleAmount>
        </Title>
        <OpenInvoiceButton 
          onClick={handleOpenInvoice}
        >
          Open Invoice
        </OpenInvoiceButton>
      </TitleSection>

      <GridSection>
          <DetailLabel><Number12 />Invoice #</DetailLabel>
          <DetailValue>{invoice.invoice_number}</DetailValue>
          <DetailLabel><Status12 />Status</DetailLabel>
          <DetailValue>{capitalize(invoice.status)}</DetailValue>

          <DetailLabel><Client12 />Client</DetailLabel>
          <DetailValue>{getClientName}</DetailValue>

          <DetailLabel><Calendar12 />Invoice Date</DetailLabel>
          <DetailValue>
            {invoice.invoice_date
              ? formatDate(invoice.invoice_date)
              : 'N/A'}
          </DetailValue>

          <DetailLabel><DueDate12 />Due Date</DetailLabel>
          <DetailValue>
            {invoice.due_date
              ? formatDate(invoice.due_date)
              : 'N/A'}
          </DetailValue>

          <DetailLabel><Money12 />Amount Due</DetailLabel>
          <DetailValue>{formattedAmountDue}</DetailValue>
      </GridSection>
      <ShareLinkContainer>
          <DetailLabel>Payment Link</DetailLabel>
          <ShareLinkWrapper>
          <ShareLink>
            {shareLink}
          </ShareLink>
            <ShareLinkButton onClick={handleCopyLink}><Copy12 />{copyButtonText}</ShareLinkButton>
          </ShareLinkWrapper>
        </ShareLinkContainer>

      <HistorySection>
        <SectionHeader>Invoice History</SectionHeader>
        {historyItems.map((item, index) => (
          <HistoryItem key={index}>
            <HistoryEvent>
              <HistoryIcon>{getIconForType(item.type)}</HistoryIcon>
              {item.event}
            </HistoryEvent>
            <HistoryDate $isLast={index === historyItems.length - 1}>
              {formatDate(item.date.toISOString())}
            </HistoryDate>
          </HistoryItem>
        ))}
        {historyItems.length === 0 && (
          <HistoryItem>No history available</HistoryItem>
        )}
      </HistorySection>
    </DetailsContainer>
  );
};

export default InvoiceDetails;