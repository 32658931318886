import React, { useMemo } from 'react';
import { TimeEntry } from '../types';
import DataTable from './DataTable';
import { ColumnDef, SortingState, OnChangeFn } from '@tanstack/react-table';
import { Time32 } from './Icon';
import { formatDuration } from '../utils/timeUtils';

interface TimeEntryListProps {
  timeEntries: TimeEntry[] | undefined;
  isLoading: boolean;
  onCreateTimeEntry: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
  onSelectionChange?: (selectedIds: string[]) => void;
  onEditTimeEntry: (timeEntry: TimeEntry) => void;
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
}

const TimeEntryList: React.FC<TimeEntryListProps> = ({
  timeEntries,
  isLoading,
  onCreateTimeEntry,
  containerRef,
  onSelectionChange,
  onEditTimeEntry,
  sorting,
  onSortingChange,
}) => {
  const timeEntryColumns: ColumnDef<TimeEntry>[] = useMemo(
    () => [
      {
        accessorKey: 'start_time',
        header: 'Start Time',
        cell: ({ getValue }) => new Date(getValue() as string).toLocaleString(),
      },
      {
        accessorKey: 'end_time',
        header: 'End Time',
        cell: ({ getValue }) => getValue() ? new Date(getValue() as string).toLocaleString() : 'In Progress',
      },
      {
        accessorKey: 'duration',
        header: 'Duration',
        cell: ({ getValue }) => formatDuration(getValue() as number),
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'is_billable',
        header: 'Billable',
        cell: ({ getValue }) => getValue() ? 'Yes' : 'No',
      },
    ],
    []
  );

  const handleRowClick = (timeEntry: TimeEntry) => {
    onEditTimeEntry(timeEntry);
  };

  const getTimeEntryRowKey = (timeEntry: TimeEntry) => timeEntry.id;

  const timeEntryEmptyState = useMemo(() => ({
    icon: <Time32 />,
    message: "No time entries found",
    subMessage: "Create a new time entry to get started",
    action: {
      label: "New Time Entry",
      onClick: onCreateTimeEntry
    }
  }), [onCreateTimeEntry]);

  const handleSelectionChange = (selectedIds: string[]) => {
    console.log('Selected time entry IDs:', selectedIds);
    if (onSelectionChange) {
      onSelectionChange(selectedIds);
    }
  };

  return (
    <DataTable
      columns={timeEntryColumns}
      data={timeEntries || []}
      isLoading={isLoading}
      onRowClick={handleRowClick}
      getRowKey={getTimeEntryRowKey}
      containerRef={containerRef}
      emptyState={timeEntryEmptyState}
      onSelectionChange={handleSelectionChange}
      sorting={sorting}
      onSortingChange={onSortingChange}
    />
  );
};

export default TimeEntryList;
