import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { InvoiceData, InvoiceItem, Payment, Service } from '../types';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import axios from 'axios';

export const useInvoiceMutations = (id: string | undefined) => {
  const queryClient = useQueryClient();

  const updateInvoiceMutation = useMutation({
    mutationFn: async (updatedInvoice: Partial<InvoiceData>) => {
      if (!id) throw new Error('Invoice ID is required');
  

      const { data, error } = await supabase
        .from('invoices')
        .update(updatedInvoice)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], (old) => old ? { ...old, ...data } : data);
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
    },
  });

  const updateInvoiceItemsMutation = useMutation({
    mutationFn: async (items: Partial<InvoiceItem>[]) => {
      if (!id) throw new Error('Invoice ID is required');

      const { data: updatedItems, error: itemsError } = await supabase
        .from('invoice_items')
        .upsert(items.map(item => ({ ...item, invoice_id: id })))
        .select();

      if (itemsError) throw itemsError;

      const { data: invoice, error: invoiceError } = await supabase
        .from('invoices')
        .select('*, items:invoice_items(*), payments(*)')
        .eq('id', id)
        .single();

      if (invoiceError) throw invoiceError;

      const totals = calculateInvoiceTotals(invoice);
      const totalPaid = invoice.payments.reduce((sum: number, payment: Payment) => sum + payment.amount, 0);
      const newAmountDue = Math.max(0, totals.total - totalPaid);

      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({ 
          subtotal: totals.subtotal, 
          total: totals.total, 
          amount_due: newAmountDue,
          ...(invoice.status !== 'draft' && { status: newAmountDue === 0 && totalPaid > 0 ? 'paid' : 'unpaid' })
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;

      return { updatedItems, updatedInvoice };
    },
    onSuccess: ({ updatedItems, updatedInvoice }) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], (old) => {
        if (!old) return updatedInvoice;
        return {
          ...old,
          ...updatedInvoice,
          items: updatedItems,
        };
      });
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
    },
  });

  const addInvoiceItemMutation = useMutation({
    mutationFn: async (newItem: Omit<InvoiceItem, 'id'>) => {
      console.log("Adding new item:", newItem);
      if (!id) throw new Error('Invoice ID is required');

      // Insert the new item
      const { data: insertedItem, error: insertError } = await supabase
        .from('invoice_items')
        .insert(newItem)
        .select()
        .single();

      if (insertError) throw insertError;
      console.log("Inserted item:", insertedItem);

      // Fetch the current invoice data including items and payments
      const { data: invoice, error: invoiceError } = await supabase
        .from('invoices')
        .select('*, items:invoice_items(*), payments(*)')
        .eq('id', id)
        .single();

      if (invoiceError) throw invoiceError;
      console.log("Fetched invoice data:", invoice);

      // Ensure invoice.items is an array and includes the new item
      const currentItems = Array.isArray(invoice.items) ? invoice.items : [];
      const updatedItems = [...currentItems, insertedItem];

      // Calculate new totals
      const totals = calculateInvoiceTotals({
        ...invoice,
        items: updatedItems,
      });
      console.log("Calculated totals:", totals);

      // Calculate total payments
      const totalPaid = invoice.payments.reduce((sum: number, payment: Payment) => sum + payment.amount, 0);
      console.log("Total paid:", totalPaid);

      // Calculate new amount due
      const newAmountDue = Math.max(0, totals.total - totalPaid);
      console.log("New amount due:", newAmountDue);

      // Update the invoice with new totals
      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({ 
          subtotal: totals.subtotal, 
          total: totals.total, 
          amount_due: newAmountDue,
          // Only change status if it's not already 'draft'
          ...(invoice.status !== 'draft' && { status: newAmountDue === 0 && totalPaid > 0 ? 'paid' : 'unpaid' })
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;
      console.log("Updated invoice:", updatedInvoice);

      return { newItem: insertedItem, updatedInvoice: { ...updatedInvoice, items: updatedItems } };
    },
    onSuccess: ({ newItem, updatedInvoice }) => {
      console.log("Mutation success. New item:", newItem, "Updated invoice:", updatedInvoice);
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return updatedInvoice;
        const updatedData = {
          ...old,
          ...updatedInvoice,
          items: Array.isArray(updatedInvoice.items) ? updatedInvoice.items : [newItem],
        };
        console.log("Updated query data:", updatedData);
        return updatedData;
      });
    },
    onError: (error) => {
      console.error("Error adding new item:", error);
    }
  });

  const reorderInvoiceItemsMutation = useMutation({
    mutationFn: async (newItems: InvoiceItem[]) => {
      if (!id) throw new Error('Invoice ID is required');
  
      const updatedItems = newItems.map((item, index) => ({
        id: item.id,
        invoice_id: id,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        taxable: item.taxable,
        order: index
      }));
  
      const { data, error } = await supabase
        .from('invoice_items')
        .upsert(updatedItems, { onConflict: 'id' })
        .select();
  
      if (error) throw error;
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return old;
        return {
          ...old,
          items: data,
        };
      });
    },
  });

  const deleteInvoiceItemMutation = useMutation({
    mutationFn: async (itemId: string) => {
      if (!id) throw new Error('Invoice ID is required');

      // First, delete related entries in time_entry_invoice_items
      const { error: timeEntryDeleteError } = await supabase
        .from('time_entry_invoice_items')
        .delete()
        .eq('invoice_item_id', itemId);

      if (timeEntryDeleteError) throw timeEntryDeleteError;

      // Then, delete the invoice item
      const { error: deleteError } = await supabase
        .from('invoice_items')
        .delete()
        .eq('id', itemId);

      if (deleteError) throw deleteError;

      // Fetch the updated invoice data including items and payments
      const { data: updatedInvoice, error: fetchError } = await supabase
        .from('invoices')
        .select('*, items:invoice_items(*), payments(*)')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;

      // Calculate new totals
      const totals = calculateInvoiceTotals(updatedInvoice);

      // Calculate total payments
      const totalPaid = updatedInvoice.payments.reduce((sum: number, payment: Payment) => sum + payment.amount, 0);

      // Calculate new amount due
      const newAmountDue = Math.max(0, totals.total - totalPaid);

      // Update the invoice with new totals
      const { data: finalInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({
          subtotal: totals.subtotal,
          total: totals.total,
          amount_due: newAmountDue,
          // Only change status if it's not already 'draft'
          ...(updatedInvoice.status !== 'draft' && { status: newAmountDue === 0 && totalPaid > 0 ? 'paid' : 'unpaid' })
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;

      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return { itemId, updatedInvoice: finalInvoice };
    },
    onSuccess: ({ itemId, updatedInvoice }) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], (oldData) => {
        if (!oldData) return updatedInvoice;
        return {
          ...oldData,
          ...updatedInvoice,
          items: (oldData.items || []).filter((item) => item.id !== itemId),
        };
      });
    },
  });

  const addPaymentMutation = useMutation({
    mutationFn: async (payment: Omit<Payment, "id">) => {
      if (!id) throw new Error('Invoice ID is required');

      const { data: paymentData, error: paymentError } = await supabase
        .from('payments')
        .insert([{ ...payment, invoice_id: id }])
        .select()
        .single();

      if (paymentError) throw paymentError;

      // Fetch the current invoice data
      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .select('total, amount_due')
        .eq('id', id)
        .single();

      if (invoiceError) {
        console.error('Error fetching invoice data:', invoiceError);
        throw new Error(`Failed to fetch invoice data: ${invoiceError.message}`);
      }

      if (!invoiceData) {
        throw new Error(`No invoice found with id: ${id}`);
      }

      // Calculate new amount due
      const newAmountDue = Math.max(0, invoiceData.amount_due - paymentData.amount);

      // Determine the new status
      const newStatus = newAmountDue === 0 && paymentData.amount > 0 ? 'paid' : 'unpaid';

      // Update the invoice with new amount due and status
      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({
          amount_due: newAmountDue,
          status: newStatus
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;

      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return { paymentData, updatedInvoice };
    },
    onSuccess: ({ paymentData, updatedInvoice }) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return updatedInvoice;
        return {
          ...old,
          ...updatedInvoice,
          payments: Array.isArray(old.payments) ? [...old.payments, paymentData] : [paymentData],
          amount_due: updatedInvoice.amount_due,
          status: updatedInvoice.status,
        };
      });
    },
    onError: (error) => {
      console.error('Error adding payment:', error);
      // You can add additional error handling here, such as showing a notification to the user
    },
  });

  const removePaymentMutation = useMutation({
    mutationFn: async (paymentId: string) => {
      if (!id) throw new Error('Invoice ID is required');

      // First, fetch the payment amount
      const { data: paymentData, error: paymentError } = await supabase
        .from('payments')
        .select('amount')
        .eq('id', paymentId)
        .single();
      if (paymentError) throw paymentError;

      // Delete the payment
      const { error: deleteError } = await supabase
        .from('payments')
        .delete()
        .eq('id', paymentId);
      if (deleteError) throw deleteError;

      // Fetch the current invoice data
      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .select('amount_due, total')
        .eq('id', id)
        .single();
      if (invoiceError) throw invoiceError;

      // Calculate new amount_due
      const newAmountDue = Math.min(invoiceData.total, invoiceData.amount_due + paymentData.amount);

      // Fetch all payments for this invoice
      const { data: payments, error: paymentsError } = await supabase
        .from('payments')
        .select('amount')
        .eq('invoice_id', id);

      if (paymentsError) throw paymentsError;

      const totalPaid = payments.reduce((sum, payment) => sum + payment.amount, 0);

      // Update the invoice
      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({
          amount_due: newAmountDue,
          status: newAmountDue === 0 && totalPaid > 0 ? 'paid' : 'unpaid'
        })
        .eq('id', id)
        .select()
        .single();
      if (updateError) throw updateError;

      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return { paymentId, updatedInvoice };
    },
    onSuccess: ({ paymentId, updatedInvoice }) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return old;
        return {
          ...old,
          payments: old.payments.filter(p => p.id !== paymentId),
          amount_due: updatedInvoice.amount_due,
          status: updatedInvoice.status,
        };
      });
    },
  });

  const deleteInvoiceMutation = useMutation({
    mutationFn: async () => {
      if (!id) throw new Error('Invoice ID is required');
  
      // First, fetch the invoice items
      const { data: invoiceItems, error: fetchError } = await supabase
        .from('invoice_items')
        .select('id')
        .eq('invoice_id', id);
  
      if (fetchError) throw fetchError;
  
      if (invoiceItems && invoiceItems.length > 0) {
        // Delete entries from the time_entry_invoice_items junction table
        const { error: timeEntryJunctionDeleteError } = await supabase
          .from('time_entry_invoice_items')
          .delete()
          .in('invoice_item_id', invoiceItems.map(item => item.id));
  
        if (timeEntryJunctionDeleteError) throw timeEntryJunctionDeleteError;
  
        // Delete entries from the expense_invoice_items junction table
        const { error: expenseJunctionDeleteError } = await supabase
          .from('expense_invoice_items')
          .delete()
          .in('invoice_item_id', invoiceItems.map(item => item.id));
  
        if (expenseJunctionDeleteError) throw expenseJunctionDeleteError;
      }
  
      // Delete invoice items
      const { error: itemsDeleteError } = await supabase
        .from('invoice_items')
        .delete()
        .eq('invoice_id', id);
  
      if (itemsDeleteError) throw itemsDeleteError;
  
      // Delete payments associated with the invoice
      const { error: paymentsDeleteError } = await supabase
        .from('payments')
        .delete()
        .eq('invoice_id', id);
  
      if (paymentsDeleteError) throw paymentsDeleteError;
  
      // Finally, delete the invoice
      const { error: invoiceDeleteError } = await supabase
        .from('invoices')
        .delete()
        .eq('id', id);
  
      if (invoiceDeleteError) throw invoiceDeleteError;
  
      // After successful deletion, remove the specific invoice from the cache
      queryClient.removeQueries({ queryKey: ['invoice', id] });
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return id;
    },
    onSuccess: (deletedInvoiceId) => {
      // Remove the specific invoice from the cache
      queryClient.removeQueries({ queryKey: ['invoice', deletedInvoiceId] });
      // Invalidate the 'invoices' list to reflect the deletion
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
    },
  });
  
  const shareInvoiceMutation = useMutation({
    mutationFn: async () => {
      if (!id) throw new Error('Invoice ID is required');
      const { data: existingInvoice, error: fetchError } = await supabase
        .from('invoices')
        .select('public_id')
        .eq('id', id)
        .single();
  
      if (fetchError) throw fetchError;
  
      if (existingInvoice.public_id) {
        return existingInvoice;
      }
  
      const { data, error } = await supabase
        .from('invoices')
        .update({ public_id: crypto.randomUUID() })
        .eq('id', id)
        .select()
        .single();
  
      if (error) throw error;
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return data;
    },
    onSuccess: (data) => {
      // Update the cached invoice data without invalidating all queries
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        return old ? { ...old, ...data } : data;
      });
    },
  });

  const updateInvoiceItemDescriptionMutation = useMutation({
    mutationFn: async ({ itemId, description }: { itemId: string; description: string }) => {
      if (!id) throw new Error('Invoice ID is required');
      const { data, error } = await supabase
        .from('invoice_items')
        .update({ description })
        .eq('id', itemId)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: (updatedItem) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return old;
        return {
          ...old,
          items: old.items.map(item => 
            item.id === updatedItem.id ? { ...item, description: updatedItem.description } : item
          ),
        };
      });
    },
  });

  const handleServiceSelect = useMutation({
    mutationFn: async ({ service, itemId }: { service: Service; itemId: string }) => {
      if (!id) throw new Error('Invoice ID is required');
      const { data, error } = await supabase
        .from('invoice_items')
        .update({
          description: service.name || "",
          price: service.price,
          taxable: service.taxable
        })
        .eq('id', itemId)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: (updatedItem) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return old;
        return {
          ...old,
          items: old.items.map(item => 
            item.id === updatedItem.id ? { ...item, ...updatedItem } : item
          ),
        };
      });
    },
  });

  const handleDownloadPDF = useMutation({
    mutationFn: async (invoiceData: InvoiceData) => {
      const session = await supabase.auth.getSession();
      if (!session.data.session) throw new Error("No active session found");

      const token = session.data.session.access_token;
      const apiUrl = process.env.NODE_ENV === "development"
        ? "http://localhost:3001/generate-pdf"
        : "/api/generate-pdf";

      const response = await axios.post(
        apiUrl,
        { invoiceData },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    },
  });

  const handleSendInvoice = useMutation({
    mutationFn: async ({ invoiceData, clientEmail, clientName }: { invoiceData: InvoiceData; clientEmail: string; clientName: string }) => {
      const session = await supabase.auth.getSession();
      if (!session.data.session) throw new Error("No active session found");

      const token = session.data.session.access_token;
      const apiUrl = process.env.NODE_ENV === "development"
        ? "http://localhost:3000/send-invoice"
        : "/api/send-invoice";

      const pdfResponse = await axios.post(
        "/api/generate-pdf",
        { invoiceData },
        { headers: { Authorization: `Bearer ${token}` }, responseType: "blob" }
      );

      const pdfBlob = new Blob([pdfResponse.data], { type: "application/pdf" });

      const formData = new FormData();
      formData.append("invoiceData", JSON.stringify(invoiceData));
      formData.append("clientEmail", clientEmail);
      formData.append("clientName", clientName);
      formData.append("pdfFile", pdfBlob, `invoice-${invoiceData.id}.pdf`);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    },
  });

  const handlePreviewHTML = useMutation({
    mutationFn: async (invoiceData: InvoiceData) => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error("No active session found");

      const token = session.access_token;
      const apiUrl = process.env.NODE_ENV === "development"
        ? "http://localhost:3001/preview-invoice-html"
        : "/api/preview-invoice-html";

      const response = await axios.post(
        apiUrl,
        { invoiceData },
        {
          responseType: "text",
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (status) => status < 500,
        }
      );

      if (response.status !== 200) {
        throw new Error(`Failed to generate HTML preview. Server responded with status ${response.status}`);
      }

      return response.data;
    },
  });

  return {
    updateInvoiceMutation,
    updateInvoiceItemsMutation,
    addInvoiceItemMutation,
    deleteInvoiceItemMutation,
    addPaymentMutation,
    removePaymentMutation,
    deleteInvoiceMutation,
    shareInvoiceMutation,
    reorderInvoiceItemsMutation,
    updateInvoiceItemDescriptionMutation,
    handleServiceSelect,
    handleDownloadPDF,
    handleSendInvoice,
    handlePreviewHTML,
  };
};