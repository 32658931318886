import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useResizeObserver } from "../hooks/useResizeObserver";

const HeaderContainer = styled.div<{
  $isLoaded: boolean;
}>`
  overflow: hidden;
  opacity: ${props => props.$isLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  min-height: 0;
`;

const HeaderContent = styled.div<{ $height: number }>`
  height: ${props => props.$height}px;
  transition: height 0.3s ease-in-out;
`;

interface AnimatedHeaderProps {
  headerColor: string;
  children: React.ReactNode;
}

const AnimatedHeader: React.FC<AnimatedHeaderProps> = ({
  headerColor,
  children,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const updateHeight = useCallback(() => {
    if (headerRef.current) {
      const newHeight = headerRef.current.scrollHeight;
      setHeight(newHeight);
      if (newHeight > 0 && !isLoaded) {
        setIsLoaded(true);
      }
    }
  }, [isLoaded]);

  useResizeObserver(headerRef, updateHeight);

  useEffect(() => {
    updateHeight();

    const images = headerRef.current?.getElementsByTagName('img');
    if (images) {
      Array.from(images).forEach(img => {
        if (img.complete) {
          updateHeight();
        } else {
          img.addEventListener('load', updateHeight);
        }
      });
    }

    return () => {
      if (images) {
        Array.from(images).forEach(img => {
          img.removeEventListener('load', updateHeight);
        });
      }
    };
  }, [children, headerColor, updateHeight]);

  return (
    <HeaderContainer $isLoaded={isLoaded}>
      <HeaderContent $height={height}>
        <div ref={headerRef}>{children}</div>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default AnimatedHeader;
