import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';
import { TimeEntry, Expense, InvoiceItem, Service } from '../types';

interface CreateInvoiceParams {
  client_id?: string | null;
  currency?: string;
  project_id?: string;
  organization_id: string;
  timeEntries?: TimeEntry[];
  expenses?: Expense[];
}

// Modify the InvoiceItem type to make 'id' optional
type PartialInvoiceItem = Omit<InvoiceItem, 'id'> & { id?: string };

const createInvoiceItems = (timeEntries: TimeEntry[], services: Service[], newInvoiceId: string): PartialInvoiceItem[] => {
  return timeEntries.map((entry, index) => {
    const service = services.find(s => s.id === entry.service_id);
    return {
      invoice_id: newInvoiceId,
      description: `${entry.service?.name || 'Service'} - ${entry.project?.name || 'Project'}`,
      quantity: entry.duration / 3600, // Convert seconds to hours
      price: service?.price || 0, // Use 0 as fallback if price is not available
      taxable: false,
      order: index,
    };
  });
};

const createExpenseItems = (expenses: Expense[], newInvoiceId: string): PartialInvoiceItem[] => {
  return expenses.map((expense, index) => ({
    invoice_id: newInvoiceId,
    description: expense.description,
    quantity: 1,
    price: expense.amount,
    taxable: false,
    order: index,
  }));
};

const calculateInvoiceTotals = (invoiceItems: InvoiceItem[], taxRate: number) => {
  const subtotal = invoiceItems.reduce((sum, item) => sum + item.quantity * item.price, 0);
  const taxableAmount = invoiceItems.filter(item => item.taxable).reduce((sum, item) => sum + item.quantity * item.price, 0);
  const taxAmount = (taxableAmount * taxRate) / 100;
  const total = subtotal + taxAmount;
  return { subtotal, total };
};

export const useCreateInvoiceMutation = () => {
  const queryClient = useQueryClient();
  const { data: organizationId, error: organizationError } = useOrganization();

  return useMutation({
    mutationFn: async (params: CreateInvoiceParams) => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');
      if (organizationError) throw new Error('Failed to fetch organization');
      if (!organizationId) throw new Error('User is not associated with an organization');

      // Fetch brand settings
      const { data: brandSettings, error: brandSettingsError } = await supabase
        .from('brand_settings')
        .select('*')
        .eq('organization_id', organizationId)
        .single();

      if (brandSettingsError) {
        console.error('Error fetching brand settings:', brandSettingsError);
      }

      const newInvoice = {
        subtotal: 0,
        amount_due: 0,
        invoice_date: new Date().toISOString(),
        due_date: new Date(new Date().setDate(new Date().getDate() + (brandSettings?.default_due_days || 30))).toISOString(),
        invoice_created_at: new Date().toISOString(),
        user_id: user.id,
        organization_id: organizationId,
        client_id: params.client_id || null,
        project_id: params.project_id || null,
        invoice_template: brandSettings?.default_template || 'simple',
        font: brandSettings?.default_font || 'Arial',
        header_color: brandSettings?.default_header_color || '#ffffff',
        header_text_color: brandSettings?.default_header_text_color || '#000000',
        background_color: brandSettings?.default_background_color || '#ffffff',
        body_text_color: brandSettings?.default_body_text_color || '#000000',
        tax_rate: brandSettings?.default_tax_rate || 0,
        status: 'draft',
        due_days: brandSettings?.default_due_days || 30,
        currency: params.currency || brandSettings?.default_currency || 'USD',
      };

      // Insert the new invoice
      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .insert([newInvoice])
        .select()
        .single();

      if (invoiceError) throw invoiceError;

      const newInvoiceId = invoiceData.id;

      // Fetch services
      const { data: services, error: servicesError } = await supabase
        .from('services')
        .select('*')
        .eq('organization_id', organizationId);

      if (servicesError) throw servicesError;

      // Create invoice items
      let invoiceItems: PartialInvoiceItem[] = [];

      if (params.timeEntries && params.timeEntries.length > 0) {
        invoiceItems = createInvoiceItems(params.timeEntries, services, newInvoiceId);
      }

      if (params.expenses && params.expenses.length > 0) {
        invoiceItems = [...invoiceItems, ...createExpenseItems(params.expenses, newInvoiceId)];
      }

      // If no time entries or expenses, add an empty invoice item
      if (invoiceItems.length === 0) {
        invoiceItems.push({
          invoice_id: newInvoiceId,
          description: '',
          quantity: 1,
          price: 0,
          taxable: false,
          order: 0,
        });
      }

      // Insert invoice items
      const { data: insertedItems, error: itemsError } = await supabase
        .from('invoice_items')
        .insert(invoiceItems)
        .select();

      if (itemsError) throw itemsError;

      // Create junction table entries
      if (params.timeEntries && params.timeEntries.length > 0) {
        const timeEntryJunctions = params.timeEntries.map((entry, index) => ({
          time_entry_id: entry.id,
          invoice_item_id: insertedItems[index].id,
        }));

        const { error: timeEntryJunctionError } = await supabase
          .from('time_entry_invoice_items')
          .insert(timeEntryJunctions);

        if (timeEntryJunctionError) throw timeEntryJunctionError;
      }

      if (params.expenses && params.expenses.length > 0) {
        const expenseJunctions = params.expenses.map((expense, index) => ({
          expense_id: expense.id,
          invoice_item_id: insertedItems[params.timeEntries?.length || 0 + index].id,
          amount: expense.amount,
        }));

        const { error: expenseJunctionError } = await supabase
          .from('expense_invoice_items')
          .insert(expenseJunctions);

        if (expenseJunctionError) throw expenseJunctionError;
      }

      // Recalculate invoice totals
      const { subtotal, total } = calculateInvoiceTotals(insertedItems as InvoiceItem[], invoiceData.tax_rate);

      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({ subtotal, amount_due: total })
        .eq('id', newInvoiceId)
        .select()
        .single();

      if (updateError) throw updateError;

      return updatedInvoice;
    },
    onSuccess: (newInvoice) => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      if (newInvoice.project_id) {
        queryClient.invalidateQueries({ queryKey: ['projectInvoices', newInvoice.project_id] });
      }
      queryClient.invalidateQueries({ queryKey: ['expenses'] });
      return newInvoice;
    },
  });
};