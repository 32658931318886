import React, { useState } from 'react';
import { EditorContent, Editor } from '@tiptap/react';
import { Play8, Trash12, Pause8, Client12, Project12, Service12 } from '../../Icon';
import { ButtonGroup, LogTimeButton, DeleteButton } from './TimeTrackerStyles';
import styled from 'styled-components';
import EntityPicker from '../../EntityPicker';
import LayoutPicker from './LayoutPicker';
import { TimeTrackerStyle, Client, Project, Service, Entity } from '../../../types';
import Switch from '../../Switch';
import MotionNumber from 'motion-number';

const DefaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #F8F7F6;
  border-radius: 12px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75);
`;

export const TimeInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: 'PP Mori';
  padding: 48px 24px 48px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export const TimeInputField = styled.input`
  width: 64px;
  height: 64px;
  line-height: 64px;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  font-size: 48px;
  font-family: 'PP Mori';
  font-family: inherit;
  text-align: center;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.8);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  background-color: transparent;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TimeInputMotion = styled(MotionNumber)`
  width: 64px;
  height: 64px;
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  line-height: 64px;
  border-radius: 8px;
  font-size: 48px;
  font-family: inherit;
  text-align: center;
  font-family: 'PP Mori';
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transform: none !important;
  opacity: 1 !important;
`;

export const TimeInputSeparator = styled.span`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
`;

const TimeInputColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimeInputLabel = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 4px;
`;

const EditorWrapper = styled.div`

  .ProseMirror {
    padding: 16px 24px;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    
    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: rgba(0, 0, 0, 0.5);
      pointer-events: none;
      height: 0;
    }

    p {
      margin: 0;
    }
  }
`;

const PickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
`;

const PickerLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const PickerRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const BillableToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
`;


// Update the interface
interface TimeTrackerLayoutProps {
  selectedClientId: string | null;
  selectedProjectId: string | null;
  selectedServiceId: string | null;
  handleClientChange: (id: string | null) => void;
  handleProjectChange: (id: string | null) => void;
  handleServiceChange: (id: string | null) => void;
  clients: Client[];
  projects: Project[];
  services: Service[];
  manualTime: string;
  handleManualTimeChange: (field: 'hours' | 'minutes' | 'seconds', value: string) => void;
  editor: Editor | null;
  isBillable: boolean;
  handleBillableToggle: (checked: boolean) => void;
  isRunning: boolean;
  isPaused: boolean;
  handleStart: () => void;
  handlePause: () => void;
  handleResume: () => void;
  handleLogTime: () => void;
  handleDelete: () => void;
  timeTrackerStyle: TimeTrackerStyle;
  onLayoutChange: (layout: TimeTrackerStyle) => void;
}

const DefaultTimeTracker: React.FC<TimeTrackerLayoutProps> = ({
  manualTime,
  handleManualTimeChange,
  editor,
  isRunning,
  isPaused,
  handleStart,
  handlePause,
  handleLogTime,
  handleDelete,
  clients,
  projects,
  services,
  selectedClientId,
  selectedProjectId,
  selectedServiceId,
  handleClientChange,
  handleProjectChange,
  handleServiceChange,
  isBillable,
  handleBillableToggle,
  handleResume,
  timeTrackerStyle,
  onLayoutChange,
}) => {
  const [editingField, setEditingField] = useState<'hours' | 'minutes' | 'seconds' | null>(null);

  const mapToEntity = (item: Client | Project | Service): Entity => ({
    id: item.id,
    name: 'name' in item ? item.name : item.full_name || '',
  });

  const clientEntities = clients.map(mapToEntity);
  const projectEntities = projects.map(mapToEntity);
  const serviceEntities = services.map(mapToEntity);

  const handleTimeInputClick = (field: 'hours' | 'minutes' | 'seconds') => {
    if (!isRunning && !isPaused) {
      setEditingField(field);
    }
  };

  const handleTimeInputBlur = () => {
    setEditingField(null);
  };

  const renderTimeInput = (field: 'hours' | 'minutes' | 'seconds', index: number) => {
    const value = parseInt(manualTime.split(':')[index], 10);
    
    if (isRunning || (isPaused && editingField !== field)) {
      return (
        <TimeInputMotion
          initial={{ y: 0, opacity: 1 }} // Prevent flying in animation on mount
          animate={{ y: 0, opacity: 1 }} // Ensure it stays in place
          value={value}
          format={{ minimumIntegerDigits: 2 }}
          transition={{
            layout: { type: 'spring', duration: 0.5 },
            y: { type: 'spring', duration: 0.5 },
            opacity: { duration: 0.5, ease: 'easeOut', times: [0, 0.3] }
          }}
          onClick={() => handleTimeInputClick(field)}
        />
      );
    } else {
      return (
        <TimeInputField
          type="number"
          value={value.toString().padStart(2, '0')}
          onChange={(e) => handleManualTimeChange(field, e.target.value)}
          onBlur={handleTimeInputBlur}
          min={0}
          max={field === 'hours' ? 23 : 59}
          autoFocus={editingField === field}
        />
      );
    }
  };

  return (
    <DefaultWrapper>
      <TimeInputWrapper>
        <TimeInputColumn>
          {renderTimeInput('hours', 0)}
          <TimeInputLabel>Hrs</TimeInputLabel>
        </TimeInputColumn>
        <TimeInputSeparator>:</TimeInputSeparator>
        <TimeInputColumn>
          {renderTimeInput('minutes', 1)}
          <TimeInputLabel>Mins</TimeInputLabel>
        </TimeInputColumn>
        <TimeInputSeparator>:</TimeInputSeparator>
        <TimeInputColumn>
          {renderTimeInput('seconds', 2)}
          <TimeInputLabel>Secs</TimeInputLabel>
        </TimeInputColumn>
      </TimeInputWrapper>
      <PickerContainer>
        <PickerLeft>
          <EntityPicker
            selectedId={selectedClientId}
            onChange={handleClientChange}
            entities={clientEntities}
            label="Client"
            allowUnassigned={true}
            icon={<Client12 />}
          />
          <EntityPicker
            selectedId={selectedProjectId}
            onChange={handleProjectChange}
            entities={projectEntities}
            label="Project"
            allowUnassigned={true}
            icon={<Project12 />}
          />
          <EntityPicker
            selectedId={selectedServiceId}
            onChange={handleServiceChange}
            entities={serviceEntities}
            label="Service"
            allowUnassigned={true}
            icon={<Service12 />}
          />
        </PickerLeft>
        <PickerRight>
          <BillableToggleWrapper>
            <Switch
              checked={isBillable}
              onChange={handleBillableToggle}
              label="Billable"
              sliderColor="#4F846A"
              sliderUncheckedColor="#E0E0E0"
              knobColor="#FFFFFF"
            />
          </BillableToggleWrapper>
        </PickerRight>
      </PickerContainer>
      <EditorWrapper>
        <EditorContent editor={editor} />
      </EditorWrapper>
      <ButtonGroup>
        {!isRunning ? (
          <StyledLogTimeButton onClick={isPaused ? handleResume : handleStart}>
            <Play8/> {isPaused ? 'Resume' : 'Start'}
          </StyledLogTimeButton>
        ) : (
          <StyledLogTimeButton onClick={handlePause}>
            <Pause8/> Pause
          </StyledLogTimeButton>
        )}
        {(isRunning || isPaused) && (
          <StyledLogTimeButton onClick={handleLogTime}>
            Log Time
          </StyledLogTimeButton>
        )}
        {(isRunning || isPaused) && (
          <StyledDeleteButton onClick={handleDelete}>
            <Trash12 />
          </StyledDeleteButton>
        )}
        <StyledLayoutPicker
          selectedLayout={timeTrackerStyle}
          onLayoutChange={onLayoutChange}
        />
      </ButtonGroup>
    </DefaultWrapper>
  );
};

const StyledLayoutPicker = styled(LayoutPicker)`
  margin-right: auto;
`;

const StyledLogTimeButton = styled(LogTimeButton)`
  margin-right: auto;
`;

const StyledDeleteButton = styled(DeleteButton)`
  margin-right: auto;
`;

export default DefaultTimeTracker;
