import { useMemo, forwardRef, useState } from 'react';
import styled from 'styled-components';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay, differenceInMinutes } from 'date-fns';
import { GroupedTimeEntry } from '../../types';

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const CalendarCell = styled.div<{ isCurrentMonth: boolean }>`
  //background-color: ${props => props.isCurrentMonth ? '#fff' : '#f5f5f5'};
  min-height: 120px;
  padding: 16px;
  cursor: pointer;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }

  &:nth-child(7n) {
    border-right: none;
  }
`;

const CalendarRow = styled.div`
  display: contents;

  &:last-child ${CalendarCell} {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const DayNumber = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
  text-align: right;
  font-size: 14px;
`;

const TimeEntry = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
  border-radius: 6px;
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.05);
  }
`;

const EntryInfo = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EntryDuration = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 8px;
  font-weight: 500;
`;

// Add this new styled component after the existing styled components
const DayName = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  padding: 12px;
  background: transparent;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;

  &:nth-child(7n) {
    border-right: none;
  }
`;

const ShowMoreButton = styled.button`
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 12px;
  margin-top: 4px;
  padding: 0;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;

const DayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const DayTotal = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
`;

interface TimeEntriesCalendarProps {
  timeEntries: GroupedTimeEntry[];
  onEntryClick: (entry: GroupedTimeEntry) => void;
  onDateClick: (date: Date) => void;
  selectedEntryId: string | null;
  currentMonth: Date;
}

// Wrap the component with forwardRef to accept a ref from the parent
const TimeEntriesCalendar = forwardRef<HTMLDivElement, TimeEntriesCalendarProps>(({
  timeEntries, 
  onEntryClick, 
  onDateClick,
  selectedEntryId,
  currentMonth
}, ref) => {
  const [expandedDays, setExpandedDays] = useState<Set<string>>(new Set());

  const calendarDays = useMemo(() => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    return eachDayOfInterval({ start: monthStart, end: monthEnd });
  }, [currentMonth]);

  const weeks = useMemo(() => {
    const weeks: Date[][] = [];
    let week: Date[] = [];
    calendarDays.forEach((day, index) => {
      week.push(day);
      if (index % 7 === 6 || index === calendarDays.length - 1) {
        weeks.push(week);
        week = [];
      }
    });
    return weeks;
  }, [calendarDays]);

  const formatDuration = (startTime: string | null, endTime: string | null) => {
    if (!startTime || !endTime) return 'N/A';
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffMinutes = differenceInMinutes(end, start);
    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes % 60;
    return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
  };

  const calculateDayTotal = (entries: GroupedTimeEntry[]): string => {
    const totalMinutes = entries.reduce((total, entry) => {
      if (entry.start_time && entry.end_time) {
        const start = new Date(entry.start_time);
        const end = new Date(entry.end_time);
        return total + differenceInMinutes(end, start);
      }
      return total;
    }, 0);

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
  };

  const formatEntryInfo = (entry: GroupedTimeEntry) => {
    const parts = [];
    if (entry.client?.full_name) {
      parts.push(entry.client.full_name);
    }
    if (entry.project?.name) {
      parts.push(entry.project.name);
    }
    return parts.join(' - ');
  };

  const toggleDayExpansion = (day: Date) => {
    const dayKey = day.toISOString();
    setExpandedDays(prev => {
      const newSet = new Set(prev);
      if (newSet.has(dayKey)) {
        newSet.delete(dayKey);
      } else {
        newSet.add(dayKey);
      }
      return newSet;
    });
  };

  return (
    <div ref={ref}>
      <CalendarWrapper>
        <CalendarGrid>
          <CalendarRow>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <DayName key={day}>
                {day}
              </DayName>
            ))}
          </CalendarRow>
          {weeks.map((week, weekIndex) => (
            <CalendarRow key={weekIndex}>
              {week.map((day: Date) => {
                const dayEntries = timeEntries.filter(entry => isSameDay(new Date(entry.start_time), day));
                const isExpanded = expandedDays.has(day.toISOString());
                const displayedEntries = isExpanded ? dayEntries : dayEntries.slice(0, 4);
                const dayTotal = calculateDayTotal(dayEntries);

                return (
                  <CalendarCell
                    key={day.toISOString()}
                    isCurrentMonth={isSameMonth(day, currentMonth)}
                    onClick={() => onDateClick(day)}
                  >
                    <DayHeader>
                      <DayNumber>{format(day, 'd')}</DayNumber>
                      <DayTotal>{dayTotal}</DayTotal>
                    </DayHeader>
                    {displayedEntries.map(entry => (
                      <TimeEntry
                        key={entry.id}
                        className="time-entry-item"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEntryClick(entry);
                        }}
                        isSelected={entry.id === selectedEntryId}
                      >
                        <EntryDuration>
                          {formatDuration(entry.start_time, entry.end_time)}
                        </EntryDuration>
                        <EntryInfo>
                          {formatEntryInfo(entry)}
                        </EntryInfo>
                      </TimeEntry>
                    ))}
                    {dayEntries.length > 4 && !isExpanded && (
                      <ShowMoreButton
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDayExpansion(day);
                        }}
                      >
                        Show {dayEntries.length - 4} more
                      </ShowMoreButton>
                    )}
                    {isExpanded && dayEntries.length > 4 && (
                      <ShowMoreButton
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDayExpansion(day);
                        }}
                      >
                        Show less
                      </ShowMoreButton>
                    )}
                  </CalendarCell>
                );
              })}
            </CalendarRow>
          ))}
        </CalendarGrid>
      </CalendarWrapper>
    </div>
  );
});

// Set a proper display name for easier debugging
TimeEntriesCalendar.displayName = 'TimeEntriesCalendar';

export default TimeEntriesCalendar;