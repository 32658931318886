import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { invoiceTemplates } from "./templates/InvoiceTemplates";
import SidebarRight from "./components/SidebarRight";
import PaymentsDrawer from "./components/PaymentsDrawer";
import NewClientDrawer from "./components/Clients/AddClientDrawer";
import { Client, InvoiceData, Payment, Organization } from "./types";
import type { InvoiceItem, Service } from "./types";
import { useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { useInvoiceMutations } from "./hooks/useInvoiceMutations";
import { useServices } from "./hooks/useServices";
import PaymentsTable from "./components/PaymentsTable";
import InvoiceTotals from "./components/Invoices/InvoiceTotals";
import InvoiceItems from "./components/Invoices/InvoiceItems";
import { supabase } from "./supabaseClient";
import { useNavigate, useParams } from '@tanstack/react-router';
import NotesEditor from "./components/NotesEditor";
import InvoiceMoreMenu from "./components/Invoices/InvoiceMoreMenu";
import { RightSidebar16 } from "./components/Icon";
import AnimatedHeader from "./components/AnimatedHeader";
import Button from "./components/Button";
import { useProjects } from "./hooks/useProjects";
import { useOrganization } from "./hooks/useOrganization";
import { invoiceRoute } from './router';
import SendInvoiceDrawer from './components/Invoices/SendInvoiceDrawer';
import { ClientSelector } from "./components/Clients/ClientSelector";
import AddServiceDrawer from './components/Services/AddServiceDrawer';
import InvoicePageSkeleton from './components/Invoices/InvoicePageSkeleton';
import { isAfter } from 'date-fns';
import { StatusBadge } from './components/StatusBadge';
import { defaultSettings } from './utils/invoiceUtils';
import { usePageContext } from './hooks/usePageContext';
import InvoiceHistoryDrawer from './components/Invoices/InvoiceHistoryDrawer';
import { useEmailTrackingData } from './hooks/useEmailTrackingData';
import { useQuery } from '@tanstack/react-query';
import { calculateInvoiceTotals } from './utils/invoiceCalculations';
import { debounce } from 'lodash';
import chroma from "chroma-js";
import UpdateOrganizationDrawer from './components/Organizations/UpdateOrganizationDrawer';
import { ClientSelectorProps } from './components/Clients/ClientSelector';
import { useInvoice, useClients, useBrandSettings } from './hooks/useInvoiceHooks';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const MainContent = styled.div<MainContentProps>`
  flex: 1;
  max-width: 960px;
  width: 100%;
  transition: max-width 0.3s ease-in-out;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$bodyTextColor};
  font-family: ${(props) => props.$font};
  box-shadow: 
  0 8px 8px rgba(0, 0, 0, 0.05),
  0 24px 32px rgba(0, 0, 0, 0.05),
  0 40px 64px rgba(0, 0, 0, 0.05),
  0 64px 80px rgba(0, 0, 0, 0.05);
`;

const ToggleButton = styled.button`
  width: 28px;
  height: 28px;
  z-index: 1;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InvoiceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
`;

const InvoiceDetailsContainer = styled.div`
  padding: 64px 64px 80px;
`;

const InvoiceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 24px;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const PaymentsContent = styled.div`
  max-width: 960px;
  width: 100%;
  padding-bottom: 80px;
`;

const AddItemRow = styled.div`
  padding: 12px;
  display: flex;
  justify-content: flex-start;
`;

const NavInvoiceTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
`;

const PaymentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 48px;

`;

const PaymentsHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

interface MainContentProps {
  $isSidebarVisible: boolean;
  $backgroundColor: string;
  $bodyTextColor: string;
  $font: string; // Add this line
}

const AddItemButton = styled(Button)`
  color: inherit;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.3);
  }
`;

const InvoicePage: React.FC = () => {
  const { id } = useParams({ from: invoiceRoute.id });
  const queryClient = useQueryClient();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewClientDrawerOpen, setIsNewClientDrawerOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isAddServiceDrawerOpen, setIsAddServiceDrawerOpen] = useState(false);
  const [isSendDrawerOpen, setIsSendDrawerOpen] = useState(false);
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  const { data: organizationId } = useOrganization();
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [localInvoice, setLocalInvoice] = useState<InvoiceData | null>(null);
  const [localChanges] = useState<Partial<InvoiceData>>({});
  const [deletingItems, setDeletingItems] = useState<Set<string>>(new Set());
  const { setPageHeaderProps } = usePageContext();

  const { data: services } = useServices();
  const navigate = useNavigate();

  const { data: brandSettings } = useBrandSettings();

  const { data: clients } = useClients();
  const { data: projects } = useProjects();
  const {
    data: invoice,
    isLoading: isLoadingInvoice,
    isError: isErrorInvoice,
  } = useInvoice(id);

  const { data: organization } = useQuery({
    queryKey: ['organization', organizationId],
    queryFn: async () => {
      if (!organizationId) return null;
      const { data, error } = await supabase
        .from('organizations')
        .select('*')
        .eq('id', organizationId)
        .single();
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });

  const [showLogo, setShowLogo] = useState(() => {
    const cachedInvoice = queryClient.getQueryData(['invoice', id, organizationId]) as InvoiceData | null;
    return cachedInvoice?.show_logo ?? true;
  });

  const [localHeaderColor, setLocalHeaderColor] = useState(() => invoice?.header_color || '');
  const [localHeaderTextColor, setLocalHeaderTextColor] = useState(() => invoice?.header_text_color || '');
  const [localBackgroundColor, setLocalBackgroundColor] = useState(() => invoice?.background_color || '');
  const [localBodyTextColor, setLocalBodyTextColor] = useState(() => invoice?.body_text_color || '');

  useEffect(() => {
    if (invoice) {
      setLocalInvoice(invoice);
      setShowLogo(invoice.show_logo ?? true);
      setCollectStripePayments(invoice.collect_stripe_payments ?? false);
      setLocalHeaderColor(invoice.header_color || brandSettings?.default_header_color || '');
      setLocalHeaderTextColor(invoice.header_text_color || brandSettings?.default_header_text_color || '');
      setLocalBackgroundColor(invoice.background_color || brandSettings?.default_background_color || '');
      setLocalBodyTextColor(invoice.body_text_color || brandSettings?.default_body_text_color || '');
    }
  }, [invoice, brandSettings]);

  const {
    updateInvoiceMutation,
    updateInvoiceItemsMutation,
    addInvoiceItemMutation,
    deleteInvoiceItemMutation,
    addPaymentMutation,
    removePaymentMutation,
    deleteInvoiceMutation,
    shareInvoiceMutation,
    reorderInvoiceItemsMutation,
    updateInvoiceItemDescriptionMutation,
    handleServiceSelect,
    handleDownloadPDF,
    handleSendInvoice,
    handlePreviewHTML,
  } = useInvoiceMutations(id);

  const debouncedUpdateInvoice = useRef(
    debounce(
      (id: string, changes: Partial<InvoiceData>) => {
        updateInvoiceMutation.mutate({ id, ...changes });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const updateInvoice = useCallback(
    <K extends keyof InvoiceData>(
      field: K,
      value: InvoiceData[K] extends string | null ? string | null : InvoiceData[K]
    ) => {
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedInvoice = { ...prev, [field]: value };
        const newTotals = calculateInvoiceTotals(updatedInvoice);
        return { ...updatedInvoice, ...newTotals };
      });

      if (id) {
        debouncedUpdateInvoice(id, { [field]: value });
      }
    },
    [id, debouncedUpdateInvoice]
  );

  const updateItem = useCallback(
    (itemId: string, field: keyof InvoiceItem, value: string | number | boolean) => {
      const sanitizedValue = (field === 'price' || field === 'quantity') && value === '' ? null : value;

      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = prev.items.map(item =>
          item.id === itemId ? { ...item, [field]: sanitizedValue } : item
        );
        return { ...prev, items: updatedItems };
      });

      if (sanitizedValue !== null && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(itemId)) {
        updateInvoiceItemsMutation.mutate([{ id: itemId, [field]: sanitizedValue }]);
      }
    },
    [updateInvoiceItemsMutation]
  );

  const addItem = useCallback(
    (serviceId?: string) => {
      if (!id) return;

      const newItem: Omit<InvoiceItem, "id"> = {
        invoice_id: id,
        description: "",
        quantity: 1,
        price: 0,
        taxable: false,
        order: localInvoice?.items?.length ?? 0,
      };

      if (serviceId) {
        const selectedService = services?.find(service => service.id === serviceId);
        if (selectedService) {
          newItem.description = selectedService.name;
          newItem.price = selectedService.price;
          newItem.taxable = selectedService.taxable;
        }
      }

      const tempId = `temp-${Date.now()}`;
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = [...(prev.items || []), { ...newItem, id: tempId, order: prev.items.length }];
        return { ...prev, items: updatedItems };
      });

      addInvoiceItemMutation.mutate(newItem, {
        onSuccess: ({ newItem: addedItem, updatedInvoice }) => {
          setLocalInvoice((prev) => {
            if (!prev) return updatedInvoice;
            const updatedItems = prev.items.map(item => 
              item.id === tempId ? { ...addedItem, order: prev.items.length - 1 } : item
            );
            return {
              ...prev,
              ...updatedInvoice,
              items: updatedItems,
              // Preserve 'draft' status if it was already set
              status: prev.status === 'draft' ? 'draft' : updatedInvoice.status
            };
          });
        },
        onError: () => {
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const updatedItems = prev.items.filter(item => item.id !== tempId);
            return { ...prev, items: updatedItems };
          });
        }
      });
    },
    [id, localInvoice, services, addInvoiceItemMutation]
  );

  const deleteItem = useCallback(async (itemId: string) => {
    if (!localInvoice || !id) return;

    setDeletingItems((prev) => new Set(prev).add(itemId));

    setLocalInvoice((prev) => {
      if (!prev) return prev;
      const updatedItems = (prev.items || []).filter((item) => item.id !== itemId);
      const reorderedItems = updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));
      return { ...prev, items: reorderedItems };
    });

    deleteInvoiceItemMutation.mutate(itemId, {
      onSuccess: ({ updatedInvoice }) => {
        setDeletingItems((prev) => {
          const newSet = new Set(prev);
          newSet.delete(itemId);
          return newSet;
        });
        setLocalInvoice((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            ...updatedInvoice,
            items: (prev.items || [])
              .filter((item) => item.id !== itemId)
              .map((item, index) => ({ ...item, order: index })),
          };
        });
      },
      onError: (error) => {
        console.error("Error deleting item:", error);
        setDeletingItems((prev) => {
          const newSet = new Set(prev);
          newSet.delete(itemId);
          return newSet;
        });
        setLocalInvoice((prev) => {
          if (!prev) return prev;
          return queryClient.getQueryData<InvoiceData>(['invoice', id]) || prev;
        });
      },
    });
  }, [deleteInvoiceItemMutation, queryClient, id]);

  const handleServiceSelectWrapper = (service: Service, itemId: string) => {
    handleServiceSelect.mutate({ service, itemId });
  };

  const handleSelectProject = useCallback(
    (projectId: string | null) => {
      setSelectedProjectId(projectId);
      updateInvoice("project_id", projectId || undefined);
    },
    [updateInvoice]
  );

  useEffect(() => {
    if (localInvoice && localInvoice.project_id) {
      setSelectedProjectId(localInvoice.project_id);
    }
  }, [localInvoice]);

  const handleDescriptionChange = (value: string, itemId: string) => {
    setLocalInvoice((prev) => {
      if (!prev) return prev;
      const updatedItems = prev.items.map(item =>
        item.id === itemId ? { ...item, description: value } : item
      );
      return { ...prev, items: updatedItems };
    });
    updateInvoiceItemDescriptionMutation.mutate({ itemId, description: value });
  };

  const handleShareInvoice = useCallback(async (): Promise<string> => {
    const result = await shareInvoiceMutation.mutateAsync();
    if (result && result.public_id) {
      const link = `${window.location.origin}/share/${result.public_id}`;
      return link;
    }
    throw new Error("Failed to generate shareable link");
  }, [shareInvoiceMutation]);

  const addPayment = useCallback(
    (payment: Omit<Payment, "id">) => {
      if (!organizationId) {
        console.error("Organization ID is missing");
        return;
      }
      addPaymentMutation.mutate(
        { ...payment, organization_id: organizationId },
        {
          onSuccess: ({ paymentData, updatedInvoice }) => {
            setLocalInvoice((prev) => {
              if (!prev) return null;
              return {
                ...prev,
                payments: Array.isArray(prev.payments) ? [...prev.payments, paymentData] : [paymentData],
                amount_due: updatedInvoice.amount_due,
                subtotal: updatedInvoice.subtotal,
                status: updatedInvoice.status,
              };
            });
          },
        }
      );
    },
    [addPaymentMutation, organizationId]
  );

  const removePayment = useCallback(
    (paymentId: string) => {
      removePaymentMutation.mutate(paymentId, {
        onSuccess: ({ updatedInvoice }) => {
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const paymentToRemove = prev.payments.find(
              (p) => p.id === paymentId
            );
            const updatedPayments = prev.payments.filter(
              (p) => p.id !== paymentId
            );
            const updatedAmountDue =
              prev.amount_due + (paymentToRemove?.amount || 0);
            return {
              ...prev,
              payments: updatedPayments,
              amount_due: updatedAmountDue,
              status: updatedInvoice.status,
            };
          });
        },
      });
    },
    [removePaymentMutation]
  );

  const deleteInvoice = useCallback(() => {
    if (
      localInvoice &&
      window.confirm(
        `Are you sure you want to delete invoice ${
          localInvoice.invoice_number || "Unknown"
        }?`
      )
    ) {
      deleteInvoiceMutation.mutate(undefined, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["invoices"] });
          navigate({ to: "/" });
        },
        onError: (error) => {
          console.error("Error deleting invoice:", error);
          alert("Failed to delete invoice. Please try again or contact support if the problem persists.");
        },
      });
    }
  }, [localInvoice, deleteInvoiceMutation, navigate, queryClient]);

  const handleDownloadPDFWrapper = async () => {
    if (!localInvoice) {
      console.error("No invoice data available");
      alert("Unable to download PDF. Invoice data is not available.");
      return;
    }

    try {
      const pdfBlob = await handleDownloadPDF.mutateAsync(localInvoice);
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice-${localInvoice.id || "unknown"}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      alert("Failed to generate PDF. Please try again later.");
    }
  };

  const handleSendInvoiceWrapper = async () => {
    if (!localInvoice || !localInvoice.client_id) {
      alert("Please select a client before sending the invoice.");
      return;
    }

    const client = clients?.find((c) => c.id === localInvoice.client_id);
    if (!client || !client.email) {
      alert("Client email not found. Please update the client information.");
      return;
    }

    try {
      await handleSendInvoice.mutateAsync({
        invoiceData: localInvoice,
        clientEmail: client.email,
        clientName: client.full_name,
      });
      alert("Invoice sent successfully!");
      setIsSendDrawerOpen(false);
    } catch (error) {
      console.error("Error sending invoice:", error);
      alert("Failed to send invoice. Please try again later.");
    }
  };

  const handlePreviewHTMLWrapper = async () => {
    if (!localInvoice) {
      console.error("No invoice data available");
      alert("Unable to preview HTML. Invoice data is not available.");
      return;
    }

    try {
      const htmlContent = await handlePreviewHTML.mutateAsync(localInvoice);
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(htmlContent);
        newWindow.document.close();
      } else {
        alert("Unable to open a new window. Please check your browser settings.");
      }
    } catch (error) {
      console.error("Error previewing HTML:", error);
      alert("Failed to preview HTML. Please try again later.");
    }
  };

  const handleInvoiceNumberChange = useCallback((value: string) => {
    updateInvoice("invoice_number", value || null);
  }, [updateInvoice]);


  const resetToDefaultSettings = useCallback(async () => {
    if (!id || !organizationId) return;

    try {
      console.log("Fetching brand settings for organization:", organizationId);
      const { data: brandSettings, error: brandSettingsError } = await supabase
        .from("brand_settings")
        .select("*")
        .eq("organization_id", organizationId)
        .single();

      if (brandSettingsError) {
        console.error("Error fetching brand settings:", brandSettingsError);
        throw brandSettingsError;
      }

      console.log("Brand settings fetched:", brandSettings);

      const defaultInvoiceSettings = {
        font: brandSettings?.default_font || defaultSettings.default_font,
        header_color: brandSettings?.default_header_color || defaultSettings.default_header_color,
        header_text_color: brandSettings?.default_header_text_color || defaultSettings.default_header_text_color,
        background_color: brandSettings?.default_background_color || defaultSettings.default_background_color,
        body_text_color: brandSettings?.default_body_text_color || defaultSettings.default_body_text_color,
        invoice_template: brandSettings?.default_template || 'simple',
        show_logo: brandSettings?.show_logo ?? true, // Add this line
      };

      console.log("Default invoice settings to be applied:", defaultInvoiceSettings);

      console.log("Updating invoice with ID:", id);
      const { data, error } = await supabase
        .from("invoices")
        .update(defaultInvoiceSettings)
        .eq("id", id)
        .select()
        .single();

      if (error) {
        console.error("Error updating invoice:", error);
        throw error;
      }

      console.log("Invoice updated successfully:", data);

      setLocalInvoice(prev => prev ? { ...prev, ...data } : data);
      setShowLogo(data.show_logo); // Add this line to update the local state
      console.log("Local invoice state updated");
    } catch (error) {
      console.error("Error resetting to default settings:", error);
    }
  }, [id, organizationId, supabase, setLocalInvoice]);

  const sortedInvoiceItems = useMemo(() => {
    return localInvoice ? orderBy(localInvoice.items, ["order"], ["asc"]) : [];
  }, [localInvoice]);

  const reorderItems = useCallback(
    (newItems: InvoiceItem[]) => {
      const updatedItems = newItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      setLocalInvoice((prev) => {
        if (!prev) return prev;
        return { ...prev, items: updatedItems };
      });
      reorderInvoiceItemsMutation.mutate(updatedItems);
    },
    [reorderInvoiceItemsMutation]
  );

  const client = useMemo(() => {
    return clients?.find((c) => c.id === localInvoice?.client_id) || null;
  }, [clients, localInvoice?.client_id]);

  const getInvoiceStatus = useCallback((invoice: InvoiceData) => {
    if (invoice.status === 'draft') return 'draft';
    if (invoice.status === 'paid') return 'paid';
    
    const dueDate = new Date(invoice.due_date);
    const today = new Date();
    
    if (isAfter(today, dueDate)) {
      return 'overdue';
    }
    
    return 'unpaid';
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (localInvoice) {
      const currentStatus = getInvoiceStatus(localInvoice);
      if (currentStatus !== localInvoice.status && isMounted) {
        updateInvoice('status', currentStatus);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [localInvoice, getInvoiceStatus, updateInvoice]);

  useEffect(() => {
    if (localInvoice) {
      const currentStatus = getInvoiceStatus(localInvoice);
      if (currentStatus !== localInvoice.status) {
        updateInvoice('status', currentStatus);
      }
    }
  }, [localInvoice, getInvoiceStatus, updateInvoice]);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/",
      parentName: "Invoices",
      title: (
        <>
          <NavInvoiceTitle>
            {localInvoice?.invoice_number || "#"}
          </NavInvoiceTitle>
          {localInvoice && (
            <StatusBadge $status={getInvoiceStatus(localInvoice)}>
              {getInvoiceStatus(localInvoice)}
            </StatusBadge>
          )}
        </>
      ),
      right: (
        <ButtonWrapper>
          <Button onClick={() => setIsSendDrawerOpen(true)} buttonType="primary">Send Invoice</Button>
          <Button onClick={() => setIsDrawerOpen(true)}>Payments</Button>
          <Button onClick={() => setIsHistoryDrawerOpen(true)}>History</Button>
          <InvoiceMoreMenu
            onDelete={deleteInvoice}
            onDownloadPDF={handleDownloadPDFWrapper}
            onPreviewHTML={handlePreviewHTMLWrapper}
            onShare={handleShareInvoice}
          />
          <ToggleButton onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
            <RightSidebar16 />
          </ToggleButton>
        </ButtonWrapper>
      ),
    });
  }, [
    setPageHeaderProps,
    localInvoice,
    isSidebarVisible,
    getInvoiceStatus,
  ]);

  const { data: emailTrackingData } = useEmailTrackingData(localInvoice?.id ?? null);

  const [collectStripePayments, setCollectStripePayments] = useState(() => {
    const cachedInvoice = queryClient.getQueryData(['invoice', id, organizationId]) as InvoiceData | null;
    return cachedInvoice?.collect_stripe_payments ?? false;
  });

  const handleToggleCollectStripePayments = useCallback((enabled: boolean) => {
    setCollectStripePayments(enabled);
    updateInvoice('collect_stripe_payments', enabled);
    console.log('Toggled Stripe payments:', enabled); // Add this log
  }, [updateInvoice]);


  const handleToggleShowLogo = useCallback((show: boolean) => {
    setShowLogo(show);
    updateInvoice('show_logo', show);
  }, [updateInvoice]);

  const [isStripeConnected, setIsStripeConnected] = useState(false);

  const checkStripeConnectionStatus = useCallback(async () => {
    if (!organizationId) return false;

    try {
      const { data, error } = await supabase
        .from('organizations')
        .select('stripe_account_id, stripe_account_verified')
        .eq('id', organizationId)
        .single();

      if (error) throw error;

      return data.stripe_account_id && data.stripe_account_verified;
    } catch (error) {
      console.error('Error checking Stripe connection status:', error);
      return false;
    }
  }, [organizationId]);

  // Fetch the Stripe connection status when the component mounts
  useEffect(() => {
    const fetchStripeStatus = async () => {
      const status = await checkStripeConnectionStatus();
      setIsStripeConnected(status);
    };

    fetchStripeStatus();
  }, [checkStripeConnectionStatus]);

  const [autoColorText, setAutoColorText] = useState(() => {
    const cachedInvoice = queryClient.getQueryData(['invoice', id, organizationId]) as InvoiceData | null;
    return cachedInvoice?.auto_color_text ?? false;
  });

  const calculateTextColor = useCallback((backgroundColor: string): string => {
    const bgColor = chroma(backgroundColor);
    const isDarkBg = bgColor.luminance() < 0.5;

    let textColor = bgColor
      .set("hsl.h", (bgColor.get("hsl.h") + 30) % 360)
      .set("hsl.s", Math.min(bgColor.get("hsl.s") * 1.5, 1));

    textColor = textColor.set("hsl.l", isDarkBg ? 0.8 : 0.2);

    const minContrast = 3.5;
    let contrast = chroma.contrast(bgColor, textColor);

    const step = isDarkBg ? -0.05 : 0.05;
    while (
      contrast < minContrast &&
      textColor.get("hsl.l") > 0 &&
      textColor.get("hsl.l") < 1
    ) {
      textColor = textColor.set("hsl.l", textColor.get("hsl.l") + step);
      contrast = chroma.contrast(bgColor, textColor);
    }

    return textColor.hex();
  }, []);

  const handleToggleAutoColor = useCallback((enabled: boolean) => {
    setAutoColorText(enabled);
    updateInvoice('auto_color_text', enabled);
    if (enabled) {
      const newHeaderTextColor = calculateTextColor(localHeaderColor);
      const newBodyTextColor = calculateTextColor(localBackgroundColor);
      setLocalHeaderTextColor(newHeaderTextColor);
      setLocalBodyTextColor(newBodyTextColor);
      updateInvoice('header_text_color', newHeaderTextColor);
      updateInvoice('body_text_color', newBodyTextColor);
    }
  }, [calculateTextColor, localHeaderColor, localBackgroundColor, updateInvoice]);

  const handleImmediateColorChange = useCallback((field: keyof InvoiceData, value: string) => {
    console.log('InvoicePage - Immediate color change:', field, value);
    switch (field) {
      case 'header_color':
        setLocalHeaderColor(value);
        if (autoColorText) {
          const newHeaderTextColor = calculateTextColor(value);
          setLocalHeaderTextColor(newHeaderTextColor);
          updateInvoice('header_text_color', newHeaderTextColor);
        }
        break;
      case 'header_text_color':
        setLocalHeaderTextColor(value);
        break;
      case 'background_color':
        setLocalBackgroundColor(value);
        if (autoColorText) {
          const newBodyTextColor = calculateTextColor(value);
          setLocalBodyTextColor(newBodyTextColor);
          updateInvoice('body_text_color', newBodyTextColor);
        }
        break;
      case 'body_text_color':
        setLocalBodyTextColor(value);
        break;
    }
    updateInvoice(field, value);
  }, [autoColorText, calculateTextColor, updateInvoice]);

  const memoizedSidebarRight = useMemo(() => (
    <SidebarRight
      projects={projects ?? []}
      selectedProjectId={selectedProjectId}
      onSelectProject={handleSelectProject}
      invoiceTemplate={localInvoice?.invoice_template ?? 'simple'} // Provide a default value
      updateInvoice={updateInvoice}
      headerColor={localHeaderColor}
      headerTextColor={localHeaderTextColor}
      backgroundColor={localBackgroundColor}
      bodyTextColor={localBodyTextColor}
      taxRate={localInvoice?.tax_rate ?? 0} // Provide a default value
      font={localInvoice?.font || brandSettings?.default_font || "GeistVF"}
      currency={localInvoice?.currency ?? 'USD'} // Provide a default value
      isVisible={isSidebarVisible}
      resetToDefaultSettings={resetToDefaultSettings}
      organizationId={organizationId ?? ''} // Provide a default value if organizationId can be null
      clients={clients || []}
      selectedClientId={localInvoice?.client_id || null}
      onSelectClient={(clientId) => updateInvoice("client_id", clientId || undefined)}
      invoiceDate={localInvoice?.invoice_date ?? null} // Use null coalescing
      dueDate={localInvoice?.due_date ?? null} // Use null coalescing
      dueDays={localInvoice?.due_days || null}
      emailTrackingData={emailTrackingData || undefined}
      invoiceNumber={localInvoice?.invoice_number || ""}
      onInvoiceNumberChange={handleInvoiceNumberChange}
      showLogo={showLogo}
      onToggleShowLogo={handleToggleShowLogo}
      collectStripePayments={collectStripePayments}
      onToggleCollectStripePayments={handleToggleCollectStripePayments}
      isStripeConnected={isStripeConnected}
      onImmediateColorChange={handleImmediateColorChange}
      autoColorText={autoColorText}
      onToggleAutoColor={handleToggleAutoColor}
    />
  ), [
    localHeaderColor, localHeaderTextColor, localBackgroundColor, localBodyTextColor,
    updateInvoice, handleImmediateColorChange, projects, selectedProjectId, handleSelectProject,
    localInvoice, brandSettings, isSidebarVisible, resetToDefaultSettings, organizationId,
    clients, emailTrackingData, showLogo, handleToggleShowLogo, collectStripePayments,
    handleToggleCollectStripePayments, isStripeConnected, handleInvoiceNumberChange,
    autoColorText, handleToggleAutoColor
  ]);

  const [isUpdateOrganizationDrawerOpen, setIsUpdateOrganizationDrawerOpen] = useState(false);

  const handleUpdateOrganization = (updatedOrganization: Organization) => {
    // Update the organization data in your state or refetch it
    queryClient.invalidateQueries({ queryKey: ['organization', organizationId] });
    console.log('Organization updated:', updatedOrganization);
  };

  // Add this new function to handle opening the drawer
  const handleOpenUpdateOrganizationDrawer = () => {
    setIsUpdateOrganizationDrawerOpen(true);
  };

  const [editingClient, setEditingClient] = useState<Client | null>(null);

  const handleCreateNewClient = () => {
    setEditingClient(null);
    setIsNewClientDrawerOpen(true);
  };

  const handleEditClient = (client: Client) => {
    setEditingClient(client);
    setIsNewClientDrawerOpen(true);
  };

  if (isLoadingInvoice) {
    return <InvoicePageSkeleton />;
  }

  if (isErrorInvoice) {
    return;
  }

  if (!localInvoice) {
    return;
  }

  const SelectedTemplate = invoiceTemplates[localInvoice.invoice_template];

  const handleCreateNewService = () => {
    setIsAddServiceDrawerOpen(true);
  };

  const handleNewServiceCreated = (newService: Service) => {
    // Add the new service as a line item
    if (id) {
      const newItem: Omit<InvoiceItem, "id"> = {
        invoice_id: id,
        description: newService.name,
        quantity: 1,
        price: newService.price,
        taxable: newService.taxable,
        order: localInvoice?.items?.length ?? 0,
      };

      // Optimistic update
      const tempId = `temp-${Date.now()}`;
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = Array.isArray(prev.items)
          ? [...prev.items, { ...newItem, id: tempId, order: prev.items.length }]
          : [{ ...newItem, id: tempId, order: 0 }];
        return { ...prev, items: updatedItems };
      });

      // Add the item to the database
      addInvoiceItemMutation.mutate(newItem, {
        onSuccess: ({ newItem: addedItem, updatedInvoice }) => {
          console.log("Successfully added item:", addedItem);
          setLocalInvoice((prev) => {
            if (!prev) return updatedInvoice;
            const updatedItems = Array.isArray(prev.items)
              ? prev.items.map(item => item.id === tempId ? { ...addedItem, order: prev.items.length - 1 } : item)
              : [{ ...addedItem, order: 0 }];
            return {
              ...prev,
              ...updatedInvoice,
              items: updatedItems,
              // Preserve 'draft' status if it was already set
              status: prev.status === 'draft' ? 'draft' : updatedInvoice.status
            };
          });
        },
        onError: (error) => {
          console.error("Error adding new item:", error);
          // Revert the optimistic update
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const updatedItems = Array.isArray(prev.items)
              ? prev.items.filter(item => item.id !== tempId)
              : [];
            return { ...prev, items: updatedItems };
          });
          // Optionally, show an error message to the user
        }
      });
    }
  };
  return (
    <PageWrapper>
      <InvoiceContainer>
        <InvoiceWrapper>
          <MainContent
            $isSidebarVisible={isSidebarVisible}
            $backgroundColor={localBackgroundColor}
            $bodyTextColor={localBodyTextColor}
            $font={localInvoice?.font || (brandSettings?.default_font ?? "Arial")}
          >
            <AnimatedHeader headerColor={localHeaderColor}>
              {SelectedTemplate && (
                <SelectedTemplate
                  invoice={{
                    ...localInvoice,
                    ...localChanges,
                    header_color: localHeaderColor,
                    header_text_color: localHeaderTextColor,
                    background_color: localBackgroundColor,
                    body_text_color: localBodyTextColor,
                    font: localInvoice?.font || brandSettings?.default_font || "Arial",
                    logo_url: localInvoice?.logo_url,
                  }}
                  updateInvoice={updateInvoice}
                  clients={clients || []}
                  ClientSelectorComponent={(props: ClientSelectorProps) => (
                    <ClientSelector
                      {...props}
                      onCreateNewClient={handleCreateNewClient}
                      onEditClient={handleEditClient}
                    />
                  )}
                  onCreateNewClient={handleCreateNewClient}
                  selectedClient={client}
                  organization={organization}
                  showLogo={showLogo}
                  onOpenUpdateOrganizationDrawer={handleOpenUpdateOrganizationDrawer}
                />
              )}
            </AnimatedHeader>
            <InvoiceDetailsContainer>
            <InvoiceItems
              invoice={{
                ...localInvoice,
                items: sortedInvoiceItems
                  .filter(item => !deletingItems.has(item.id) && item.id !== undefined)
                  .map(item => ({ ...item, id: item.id as string }))
              }}
              services={services || []}
              handleServiceSelect={handleServiceSelectWrapper}
              handleDescriptionChange={handleDescriptionChange}
              updateItem={updateItem}
              deleteItem={deleteItem}
              reorderItems={reorderItems}
              onCreateNewService={handleCreateNewService}
              backgroundColor={localBackgroundColor}
            />
              <AddItemRow>
                <AddItemButton onClick={() => addItem()}>Add Item</AddItemButton>
              </AddItemRow>
              {localInvoice && (
                <InvoiceTotals
                  invoice={localInvoice}
                  bodyTextColor={localBodyTextColor}
                />
              )}
            
            </InvoiceDetailsContainer>
            <NotesEditor
                initialContent={localInvoice.notes || ""}
                onUpdate={(content) => updateInvoice("notes", content)}
                backgroundColor={localBackgroundColor}
              />
          </MainContent>
          <PaymentsContent>
            <PaymentsHeader>
            <PaymentsHeading>Payments</PaymentsHeading><Button onClick={() => setIsDrawerOpen(true)}>
              Manage Payments
            </Button>
            </PaymentsHeader>

            <PaymentsTable
              payments={localInvoice.payments}
              removePayment={removePayment}
            />
          </PaymentsContent>
        </InvoiceWrapper>
        {memoizedSidebarRight}
      </InvoiceContainer>

      <PaymentsDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        payments={localInvoice?.payments || []}
        addPayment={addPayment}
        removePayment={removePayment}
        amount_due={localInvoice?.amount_due || 0}
        organizationId={organizationId}
        invoiceStatus={localInvoice?.status || 'draft'}
      />
      <NewClientDrawer
        isOpen={isNewClientDrawerOpen}
        setIsOpen={setIsNewClientDrawerOpen}
        onClientAdded={(updatedClient) => {
          queryClient.setQueryData<Client[]>(["clients"], (old) => {
            if (editingClient) {
              // If editing, replace the old client with the updated one
              return (old || []).map(c => c.id === updatedClient.id ? updatedClient : c);
            } else {
              // If creating, add the new client to the list
              return [...(old || []), updatedClient];
            }
          });
          updateInvoice("client_id", updatedClient.id);
          setIsNewClientDrawerOpen(false);
          setEditingClient(null); // Reset editing client
        }}
        editingClient={editingClient}
      />
      <SendInvoiceDrawer
        isOpen={isSendDrawerOpen}
        setIsOpen={setIsSendDrawerOpen}
        invoice={localInvoice}
        client={client}
        onSendInvoice={handleSendInvoiceWrapper}
        onDownloadPDF={handleDownloadPDFWrapper}
        onOverlayClick={() => setIsSendDrawerOpen(false)}
      />
      <AddServiceDrawer
        isOpen={isAddServiceDrawerOpen}
        setIsOpen={setIsAddServiceDrawerOpen}
        serviceToEdit={null}
        onServiceCreated={handleNewServiceCreated}
      />
      <InvoiceHistoryDrawer
        isOpen={isHistoryDrawerOpen}
        setIsOpen={setIsHistoryDrawerOpen}
        payments={localInvoice?.payments || []}
        emailTrackingData={emailTrackingData || null}
        invoice_created_at={localInvoice?.invoice_created_at || ''}
        currency={localInvoice?.currency || 'USD'}
      />
      <UpdateOrganizationDrawer
        isOpen={isUpdateOrganizationDrawerOpen}
        setIsOpen={setIsUpdateOrganizationDrawerOpen}
        onOrganizationUpdated={handleUpdateOrganization}
      />
    </PageWrapper>
  );
};

export default InvoicePage;