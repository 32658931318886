import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from '@tanstack/react-router';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { supabase } from './supabaseClient';
import { invoiceTemplates } from './templates/InvoiceTemplates';
import { Client, InvoiceData, Payment, Organization } from './types';
import { calculateInvoiceTotals } from './utils/invoiceCalculations';
import InvoiceItems from './components/Invoices/InvoiceItems';
import InvoiceTotals from './components/Invoices/InvoiceTotals';
import AnimatedHeader from './components/AnimatedHeader';
import RightDrawerComponent from './RightDrawer';
import Button from './components/Button';
import { ClientSelector } from './components/Clients/ClientSelector';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  justify-content: center;
  overflow-y: auto;
  height: 100vh;
  background-color: #F8F7F6;


  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;

  }
`;

const InvoiceDetailsWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  padding: 80px 32px;
  box-sizing: border-box;
  position: sticky;
  top: 0;

  @media (max-width: 1000px) {
    max-width: 100%;
    position: static;
    padding: 32px;
    order: -1;
  }
`;

const InvoiceDetail = styled.div`
  margin-bottom: 16px;
`;

const DetailLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: rgba(0,0,0,0.5);
  margin-bottom: 4px;
`;

const DetailValue = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const InvoiceTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`;

const MainContent = styled.div<{ $backgroundColor: string; $bodyTextColor: string; $font: string }>`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$bodyTextColor};
  font-family: ${(props) => props.$font};
  box-shadow: 0 4px 80px rgba(0, 0, 0, 0.1);

  @media (max-width: 1000px) {
  }
`;

const InvoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px;
  box-sizing: border-box;
  max-width: 960px;
  width: 100%;

  @media (max-width: 1000px) {
    padding: 16px;
    width: 100%; // Ensure full width on mobile
  }
`;

const InvoiceDetailsContainer = styled.div`
  padding: 64px 64px 80px;

  @media (max-width: 1000px) {
    padding: 32px 16px;
  }
`;

const PaymentFormContainer = styled.div`
  padding: 20px;
`;

const InvoiceAmount = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const PaymentButton = styled(Button)<{ $isPaid: boolean; $isVisible: boolean }>`
  background-color: ${props => props.$isPaid ? '#45a049' : '#FFFFFF'};
  padding: 8px 16px;
  text-align: center;
  justify-content: center;
  display: ${props => props.$isVisible ? 'flex' : 'none'}; // Add this line
  border: none;
  border-radius: 8px;
  cursor: ${props => props.$isPaid ? 'default' : 'pointer'};
  font-size: 14px;
  margin-top: 24px;
  transition: background-color 0.3s ease;
  width: 100%;
  font-weight: 500;
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
  color: ${props => props.$isPaid ? '#FFFFFF' : 'rgba(0,0,0,0.8)'};

  &:hover {
    box-shadow: 0px 2px 1px hsla(0, 0%, 0%, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
    background-color: ${props => props.$isPaid ? '#45a049' : '#FFFFFF'};
    transform: ${props => props.$isPaid ? 'none' : 'translateY(-1px)'};
  }

  &:active {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
    transform: ${props => props.$isPaid ? 'none' : 'translateY(0) scale(0.99)'};
  }

  &:disabled {
    background-color: ${props => props.$isPaid ? '#4CAF50' : '#e0e0e0'};
    cursor: not-allowed;
    color: ${props => props.$isPaid ? '#FFFFFF' : 'rgba(0,0,0,0.3)'};
    box-shadow: none;

    &:hover {
      transform: none;
    }
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const PaymentsList = styled.div`
  padding: 32px 32px 80px;
  width: 100%;
  max-width: 960px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 32px 16px;
  }

`;

const PaymentItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const PaymentForm = ({ onClose, amount, invoiceId, organizationId }: { onClose: () => void; amount: number; invoiceId: string; organizationId: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [paymentElementReady, setPaymentElementReady] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements || !paymentElementReady) {
      return;
    }

    setProcessing(true);

    try {
      const { error: submitError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
        },
        redirect: 'if_required',
      });

      if (submitError) {
        setError(submitError.message ?? 'An unknown error occurred');
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        // Record the payment
        const response = await fetch('/api/record-payment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            invoiceId,
            amount: paymentIntent.amount / 100, // Convert cents to dollars
            paymentDate: new Date().toISOString().split('T')[0], // Format as YYYY-MM-DD
            notes: 'Payment processed through Stripe',
            userId: null, // Set this if you have user authentication
            organizationId,
            paymentMethod: paymentIntent.payment_method_types[0]
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to record payment');
        }

        onClose(); // Call onClose after successful payment
      }
    } catch (err) {
      setError('An unexpected error occurred while processing your payment');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InvoiceAmount>Amount Due: ${amount.toFixed(2)}</InvoiceAmount>
      {stripe && elements ? (
        <>
          <PaymentElement 
            onReady={() => setPaymentElementReady(true)}
            onChange={(event) => {
              if (event.complete) {
                setError(null);
              } else if (event.empty) {
                setError('Please fill in your payment details');
              } else {
                setError(null);
              }
            }}
          />
          <PaymentButton 
            type="submit" 
            disabled={!stripe || !paymentElementReady || processing}
            $isPaid={false}
            $isVisible={true}
          >
            {processing ? 'Processing...' : `Pay $${amount.toFixed(2)}`}
          </PaymentButton>
        </>
      ) : (
        <div>Loading payment form...</div>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </form>
  );
};

const PublicInvoicePage: React.FC = () => {
  const { public_id } = useParams({ from: '/share/$public_id' });
  const [invoice, setInvoice] = useState<InvoiceData | null>(null);
  const [client, setClient] = useState<Client | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [isPaymentDrawerOpen, setIsPaymentDrawerOpen] = useState(false);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [organization, setOrganization] = useState<Organization | null>(null);

  const fetchInvoiceAndPayments = useCallback(async () => {
    if (!public_id) {
      setError("No public ID provided");
      return;
    }

    try {
      const { data, error } = await supabase
        .from('invoices')
        .select(`
          *,
          organizations (id, business_name, logo_url, name, address_line1, address_line2, city, state, postal_code, country),
          invoice_items (*),
          payments (*),
          clients (id, full_name, email, address_line1, address_line2, city, state, postal_code, country)
        `)
        .eq('public_id', public_id)
        .single();

      if (error) throw new Error(`Error fetching invoice: ${error.message}`);
      if (!data) throw new Error(`No invoice found with ID: ${public_id}`);

      const { organizations, invoice_items, payments, clients, ...invoiceData } = data;

      setInvoice({
        ...invoiceData,
        business_name: organizations?.business_name,
        logo_url: organizations?.logo_url,
        items: invoice_items || [],
        payments: payments || [],
        show_logo: invoiceData.show_logo // Ensure this field is included
      });
      setOrganization(organizations);
      setPayments(payments || []);
      setClient(clients);

    } catch (error) {
      console.error('Unexpected error:', error);
      setError(`An unexpected error occurred: ${error instanceof Error ? error.message : String(error)}`);
      setInvoice(null);
    }
  }, [public_id]);

  useEffect(() => {
    fetchInvoiceAndPayments();
  }, [fetchInvoiceAndPayments]);

  const handlePaymentSuccess = useCallback(() => {
    setIsPaymentDrawerOpen(false);
    fetchInvoiceAndPayments();
  }, [fetchInvoiceAndPayments]);

  const handlePayButtonClick = useCallback(async () => {
    if (!invoice) return;
    if (!invoice.collect_stripe_payments) {
      setError('Online payments are not enabled for this invoice.');
      return;
    }

    try {
      const response = await fetch('/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ invoiceId: invoice.id }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create payment intent: ${response.status} ${errorText}`);
      }

      const data = await response.json();
      
      if (data.isPaid) {
        console.log('Invoice is already paid');
      } else {
        setClientSecret(data.clientSecret);
        setStripePromise(loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, { stripeAccount: data.stripeAccountId }));
        setIsPaymentDrawerOpen(true);
      }
    } catch (error) {
      console.error('Error creating payment intent:', error);
      setError('Failed to initialize payment. Please try again later.');
    }
  }, [invoice]);

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
        <p>Public ID: {public_id || 'Not provided'}</p>
      </div>
    );
  }

  if (!invoice) return '';

  const SelectedTemplate = invoiceTemplates[invoice.invoice_template as keyof typeof invoiceTemplates];
  const totals = calculateInvoiceTotals(invoice);

  const mockOnCreateNewClient = () => {
    console.log('Create new client functionality is not available in public view');
  };

  return (
    <PageWrapper>
      <InvoiceWrapper>
        <MainContent
          $backgroundColor={invoice.background_color || '#ffffff'}
          $bodyTextColor={invoice.body_text_color || '#000000'}
          $font={invoice.font || 'Arial'}
        >
          <AnimatedHeader headerColor={invoice.header_color || '#ffffff'}>
            {SelectedTemplate && (
              <SelectedTemplate
                invoice={{...invoice, logo_url: invoice.logo_url}}
                updateInvoice={() => {}}
                isReadOnly={true}
                clients={client ? [client] : []}
                onCreateNewClient={mockOnCreateNewClient}
                selectedClient={client}
                organization={organization}
                showLogo={invoice.show_logo} // Use the show_logo value from the invoice
                onOpenUpdateOrganizationDrawer={() => {}}
                ClientSelectorComponent={({ clients, selectedClientId, onSelectClient, onCreateNewClient, headerTextColor }) => (
                  <ClientSelector
                    clients={clients}
                    selectedClientId={selectedClientId}
                    onSelectClient={onSelectClient}
                    onCreateNewClient={onCreateNewClient}
                    headerTextColor={headerTextColor}
                    isPreview={true}
                    disabled={true}
                    onEditClient={() => {}}
                  />
                )}
              />
            )}
          </AnimatedHeader>
          <InvoiceDetailsContainer>
            <InvoiceItems
              invoice={{ ...invoice, items: invoice.items || [] }}
              services={[]}
              handleServiceSelect={() => {}}
              handleDescriptionChange={() => {}}
              updateItem={() => {}}
              deleteItem={() => {}}
              reorderItems={() => {}}
              isReadOnly={true}
              onCreateNewService={() => {}}
              backgroundColor={invoice.background_color || '#ffffff'}
            />
            <InvoiceTotals
              invoice={{
                ...invoice,
                subtotal: totals.subtotal,
                amount_due: invoice.amount_due,
              }}
              bodyTextColor={invoice.body_text_color || '#000000'}
            />
          </InvoiceDetailsContainer>
        </MainContent>
        <PaymentsList>
          <h3>Payments</h3>
          {payments.length > 0 ? (
            payments.map((payment) => (
              <PaymentItem key={payment.id}>
                <span>{new Date(payment.payment_date).toLocaleDateString()}</span>
                <span>${payment.amount.toFixed(2)}</span>
                <span>{payment.payment_method || 'N/A'}</span>
              </PaymentItem>
            ))
          ) : (
            <p>No payments recorded yet.</p>
          )}
        </PaymentsList>
      </InvoiceWrapper>
      <InvoiceDetailsWrapper>
        <InvoiceDetail>
          <InvoiceTitle>
            Invoice #{invoice.invoice_number} from
          </InvoiceTitle>
          <InvoiceTitle>
            {invoice.business_name || 'Not specified'}
          </InvoiceTitle>
        </InvoiceDetail>
        <InvoiceDetail>
          <DetailLabel>Amount Due</DetailLabel>
          <DetailValue>${invoice.amount_due.toFixed(2)}</DetailValue>
        </InvoiceDetail>
        <InvoiceDetail>
          <DetailLabel>Due Date</DetailLabel>
          <DetailValue>{new Date(invoice.due_date).toLocaleDateString()}</DetailValue>
        </InvoiceDetail>
        <PaymentButton 
          onClick={handlePayButtonClick}
          disabled={!invoice || invoice.amount_due <= 0}
          $isPaid={!invoice || invoice.amount_due <= 0}
          $isVisible={invoice?.collect_stripe_payments ?? false} // Add this line
        >
          {!invoice || invoice.amount_due <= 0 ? 'Invoice Paid' : 'Pay Invoice'}
        </PaymentButton>
        {invoice.notes && (
          <div dangerouslySetInnerHTML={{ __html: invoice.notes }} />
        )}
      </InvoiceDetailsWrapper>
      <RightDrawerComponent
        isOpen={isPaymentDrawerOpen}
        setIsOpen={setIsPaymentDrawerOpen}
        title="Pay Invoice"
      >
        <PaymentFormContainer>
          {clientSecret && stripePromise && invoice ? (
            <Elements 
              stripe={stripePromise} 
              options={{ 
                clientSecret,
                appearance: { theme: 'stripe' },
              }}
            >
              <PaymentForm 
                onClose={handlePaymentSuccess}
                amount={invoice.amount_due}
                invoiceId={invoice.id}
                organizationId={invoice.organization_id || ''}
              />
            </Elements>
          ) : (
            <div>Loading payment details...</div>
          )}
        </PaymentFormContainer>
      </RightDrawerComponent>
    </PageWrapper>
  );
};

export default PublicInvoicePage;