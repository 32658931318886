import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import type { GroupedTimeEntry, Client, Project, OrganizationUser } from '../../types';
import { formatDuration } from '../../utils/timeUtils';
import { Invoice12, Checkmark8 } from '../Icon';
import TimeDisplay from './TimeDisplay';

const EntryWrapper = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 40px 8px 40px;
  cursor: pointer;
  background-color: ${props => props.$isSelected ? 'rgba(0,0,0,0.05)' : 'transparent'};
  transition: background-color 0.1s ease-in-out;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.8);
  box-shadow: ${props => props.$isSelected ? '0 1px 0 rgba(0,0,0,0.1);' : '0 1px 0 rgba(0,0,0,0.05);'};
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  gap: 8px;

  &:hover {
    background-color: ${props => props.$isSelected ? 'rgba(0,0,0,0.05)' : 'rgba(0,0,0,0.025)'};
    
    & > * {
      opacity: 1 !important;
    }
  }
`;

const DurationWrapper = styled.div<{ $isInvoiced: boolean }>`
  font-weight: 400;
  margin-right: 16px;
  min-width: 60px;
  width: 100%;
  font-feature-settings: 'tnum';
  opacity: ${props => props.$isInvoiced ? '0.5' : '1'};
  display: flex;
  flex-direction: row;
  transition: opacity 0.1s ease-in-out;
`;

const InvoiceLink = styled.span`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 4px;
  white-space: nowrap;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  &:hover {
    background-color: rgba(0,0,0,0.05);
  }
`;

const CheckboxContainer = styled.div<{ $isSelected: boolean }>`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${props => props.$isSelected ? 1 : 0};
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const StyledCheckbox = styled.div<{ $isSelected: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid ${props => props.$isSelected ? '#000' : 'rgba(0, 0, 0, 0.2)'};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.$isSelected ? '#000' : 'transparent'};

  svg {
    display: ${props => props.$isSelected ? 'block' : 'none'};
    path {
      fill: #fff;
    }
  }
`;

interface TimeEntryComponentProps {
  entry: GroupedTimeEntry;
  onImmediateUpdate: (updatedEntry: Partial<GroupedTimeEntry>, isEditing?: boolean) => void;
  isSelected: boolean;
  clients: Client[];
  projects: Project[];
  organizationUsers: OrganizationUser[];
  onClick: () => void;
  onInvoiceClick: (invoiceId: string) => void;
  timeFormat: 'decimal' | 'hourMinute';
  onSelect: (isSelected: boolean) => void;
}

const TimeEntryComponent: React.FC<TimeEntryComponentProps> = ({
  entry,
  isSelected,
  onClick,
  onInvoiceClick,
  timeFormat,
  onSelect,
}) => {
  const [currentDuration, setCurrentDuration] = useState(entry.duration);

  const isRunning = entry.start_time && !entry.end_time;

  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (isRunning) {
      interval = setInterval(() => {
        const now = new Date();
        const startTime = new Date(entry.start_time);
        const elapsedSeconds = Math.floor((now.getTime() - startTime.getTime()) / 1000);
        setCurrentDuration(elapsedSeconds);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRunning, entry.start_time]);

  const invoiceId = entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id;
  const isInvoiced = !!invoiceId;

  const formatTime = (duration: number) => {
    if (timeFormat === 'hourMinute') {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      return `${hours}hrs ${minutes}mins`;
    }
    return formatDuration(duration);
  };

  return (
    <EntryWrapper onClick={onClick} $isSelected={isSelected} className="time-entry-item">
      <CheckboxContainer
        $isSelected={isSelected}
        onClick={(e) => {
          e.stopPropagation();
          onSelect(!isSelected);
        }}
      >
        <StyledCheckbox $isSelected={isSelected}>
          <Checkmark8 />
        </StyledCheckbox>
      </CheckboxContainer>
      <DurationWrapper $isInvoiced={isInvoiced}>
        {formatTime(currentDuration)}
      </DurationWrapper>
      
      <TimeDisplay 
        startTime={entry.start_time} 
        endTime={entry.end_time || undefined} 
        isInvoiced={isInvoiced} 
      />
      
      {invoiceId && (
        <InvoiceLink
          onClick={(e) => {
            e.stopPropagation();
            onInvoiceClick(invoiceId);
          }}
        >
          <Invoice12 style={{ marginRight: '4px' }} />
          Invoice
        </InvoiceLink>
      )}
    </EntryWrapper>
  );
};

export default React.memo(TimeEntryComponent);
