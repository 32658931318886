import React, { useRef } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabaseClient';
import Compressor from 'compressorjs';
import { Close8 } from './Icon';
import Button from './Button';

const UploaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  margin-right: 16px;
`;

const ImagePreview = styled.img`
  max-width: 48px;
  max-height: 48px;
  object-fit: contain;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: rgba(0,0,0,0.8);
  border: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;

  svg path {
    fill: rgba(255,255,255,0.8);
  }

  &:hover {
    svg path {
      fill: rgba(255,255,255,1);
    }
  }
`;

const HoverContainer = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

interface ImageUploaderProps {
  currentImage: string | null;
  onUpload: (imageUrl: string) => void;
  onRemove: (fileName: string | null) => Promise<void>;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ currentImage, onUpload, onRemove }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 8);
    return `${timestamp}-${randomString}`;
  };

  const compressImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 800,
        maxHeight: 800,
        success(result) {
          resolve(result as File);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const compressedFile = await compressImage(file);
        const fileExt = file.name.split('.').pop();
        const fileName = `${generateUniqueId()}.${fileExt}`;

        const { data, error } = await supabase.storage
          .from('logos')
          .upload(fileName, compressedFile, {
            cacheControl: '3600',
            upsert: false
          });

        if (error) throw error;

        const { data: publicUrlData } = supabase.storage
          .from('logos')
          .getPublicUrl(data.path);

        if (!publicUrlData) throw new Error('Failed to get public URL');

        onUpload(publicUrlData.publicUrl);
      } catch (error) {
        // Handle the error (e.g., show an error message to the user)
      }
    }
  };

  const handleRemove = async () => {
    if (currentImage) {
      try {
        const fileName = currentImage.split('/').pop();
        if (fileName) {
          await onRemove(fileName);
        }
      } catch (error) {
        // Handle the error (e.g., show an error message to the user)
      }
    } else {
      await onRemove(null);
    }
  };

  return (
    <UploaderContainer>
      {currentImage && (
        <ImagePreviewContainer>
          <HoverContainer>
            <ImagePreview src={currentImage} alt="Uploaded logo" />
            <DeleteButton onClick={handleRemove}>
              <Close8 />
            </DeleteButton>
          </HoverContainer>
        </ImagePreviewContainer>
      )}
      <Button size="medium" buttonType="secondary" onClick={handleButtonClick}>
        {currentImage ? 'Change Logo' : 'Upload Logo'}
      </Button>
      <HiddenInput
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        accept="image/*"
      />
    </UploaderContainer>
  );
};

export default ImageUploader;