import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';
import AnimatedAmount from '../components/AnimatedAmount';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import { Organization, Client } from '../types';

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string }>(props => ({
  style: {
    backgroundColor: props.$headerColor || '#f8f8f8',
  },
}))`
  padding: 80px;
`;

const HeaderContent = styled.div<{ $headerTextColor: string }>`
  color: ${props => props.$headerTextColor || 'inherit'};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
`;

const AmountDue = styled.div`
  margin-bottom: 1.5rem;
`;

const AmountDueLabel = styled.div`
  font-size: 0.9rem;
  opacity: 0.66;
`;

const AmountDueValue = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;

const DetailRow = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  max-width: 256px;
  width: 100%;
  white-space: nowrap;
`;

const DetailLabel = styled.span`
  opacity: 0.66;
  margin-right: 0.5rem;
`;

const Input = styled.input`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  margin: 0;
  text-align: right;
  color: inherit;
  width: ${props => props.width || 'auto'};
  min-width: 60px;
  border-radius: 8px;

  &::placeholder {
    color: inherit;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`;

const Logo = styled.img`
  max-width: 125px;
  width: fit-content;
  max-height: 125px;
  object-fit: contain;
  margin-bottom: 20px;
`;

const TopLeftItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
`;

const TopRightItem = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;

const BottomLeftItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
`;

const OrganizationAddress = styled.div`
  white-space: pre-line;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: rgba(0,0,0,0.05);
  }
`;

const BottomRightItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

interface DetailedHeaderProps extends Omit<InvoiceTemplatesProps, 'onCreateNewClient'> {
  organization: Organization | null;
  onOpenUpdateOrganizationDrawer: () => void;
  onCreateNewClient: (client?: Client) => void;
}

export const DetailedHeader: React.FC<DetailedHeaderProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient,
  organization,
  showLogo,
  onOpenUpdateOrganizationDrawer
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');
  const [inputWidth, setInputWidth] = useState('auto');
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
    updateInputWidth();
  }, [invoice.invoice_number]);

  useEffect(() => {
    updateInputWidth();
  }, [localInvoiceNumber]);

  const updateInputWidth = () => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.offsetWidth;
      setInputWidth(`${width + 8}px`); // Add 8px for padding
    }
  };

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  const getFormattedOrganizationAddress = (org: Organization) => {
    const addressParts = [
      org.business_name || org.name,
      org.address_line1,
      org.address_line2,
      `${org.city}${org.state ? `, ${org.state}` : ''} ${org.postal_code}`,
      org.country
    ].filter(Boolean);
    return addressParts.join('\n');
  };

  const handleClientSelection = (client: Client | undefined) => {
    updateInvoice('client_id', client?.id || undefined);
  };

  return (
    <FontWrapper $font={invoice.font || 'Arial'}>
      <HeaderContainer $headerColor={invoice.header_color}>
        <HeaderContent $headerTextColor={invoice.header_text_color}>
          <TopLeftItem>
            {showLogo && invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
          </TopLeftItem>
          <TopRightItem>
          <AmountDue>
          <AmountDueLabel>Amount Due ({invoice.currency})</AmountDueLabel>
          <AmountDueValue>
              <AnimatedAmount value={totals.amountDue} />
            </AmountDueValue>
          </AmountDue>
          </TopRightItem>
          <BottomLeftItem>
            <ClientSelectorComponent
              clients={clients}
              selectedClientId={invoice.client_id || null}
              onSelectClient={handleClientSelection}
              disabled={isReadOnly}
              onCreateNewClient={onCreateNewClient}
              headerTextColor={invoice.header_text_color}
            />
            {organization && (
              <OrganizationAddress onClick={onOpenUpdateOrganizationDrawer}>
                {getFormattedOrganizationAddress(organization)}
              </OrganizationAddress>
            )}
          </BottomLeftItem>
          <BottomRightItem>
          <DetailRow>
          <DetailLabel>Invoice Date</DetailLabel>
          <DatePicker
            id="invoice-date"
            label="Invoice Date"
            selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
            onChange={handleInvoiceDateChange}
            disabled={isReadOnly}
            isInvoiceDate={true}
            customFontSize={true}
          />
        </DetailRow>
        <DetailRow>
          <DetailLabel>Due Date</DetailLabel>
          <DatePicker
            id="due-date"
            label="Due Date"
            selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
            onChange={handleDueDateChange}
            disabled={isReadOnly}
            onSetDueDate={handleDueDaysChange}
            dueDays={invoice.due_days ?? 0}
            invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
            customFontSize={true}
          />
        </DetailRow>
        <DetailRow>
          <DetailLabel>Invoice #</DetailLabel>
          <Input
            type="text"
            value={localInvoiceNumber}
            onChange={handleInvoiceNumberChange}
            placeholder="N/A"
            readOnly={isReadOnly}
            width={inputWidth}
          />
          <HiddenSpan ref={hiddenSpanRef}>{localInvoiceNumber || 'N/A'}</HiddenSpan>
        </DetailRow>
          </BottomRightItem>
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};