import React from 'react';
import styled from 'styled-components';

const SummaryCardsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 20px 40px 24px;
  overflow-x: auto;
`;

const SummaryCard = styled.div<{ $isSelected?: boolean; $selectedBackgroundColor?: string }>`
  user-select: none;
  padding: 16px 48px 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-feature-settings: 'tnum';
  position: relative;
  color: ${props => props.$isSelected ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0.8)'};
  background-color: ${props => props.$isSelected 
    ? (props.$selectedBackgroundColor || 'rgba(0, 0, 0, 0.9)') 
    : 'rgba(0,0,0,0.0)'};
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 128px;

  &:hover {
    background-color: ${props => props.$isSelected 
      ? (props.$selectedBackgroundColor || 'rgba(0, 0, 0, 0.8)') 
      : 'rgba(0,0,0,0.05)'};
  }

  &:active {
    transform: scale(0.995);
  }
`;

const SummaryTitle = styled.div`
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
  opacity: 0.75;
`;

const SummaryAmount = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
`;

export interface SummaryCardProps {
  title: string;
  amount: string | number;
  onClick: () => void;
  isSelected?: boolean;
  selectedBackgroundColor?: string;
}

const SummaryCardComponent: React.FC<SummaryCardProps> = ({ 
  title, 
  amount, 
  onClick, 
  isSelected,
  selectedBackgroundColor
}) => (
  <SummaryCard 
    onClick={onClick} 
    $isSelected={isSelected}
    $selectedBackgroundColor={selectedBackgroundColor}
  >
    <SummaryTitle>{title}</SummaryTitle>
    <SummaryAmount>{amount}</SummaryAmount>
  </SummaryCard>
);

export interface SummaryCardsProps {
  cards: SummaryCardProps[];
}

const SummaryCards: React.FC<SummaryCardsProps> = ({ cards }) => {
  return (
    <SummaryCardsContainer>
      {cards.map((card, index) => (
        <SummaryCardComponent
          key={index}
          title={card.title}
          amount={card.amount}
          onClick={card.onClick}
          isSelected={card.isSelected}
          selectedBackgroundColor={card.selectedBackgroundColor}
        />
      ))}
    </SummaryCardsContainer>
  );
};

export default SummaryCards;