import React, { useState, useEffect, useRef } from 'react';
import { EditorContent, Editor } from '@tiptap/react';
import { Play8, Trash12, Pause8, Client12, Project12, Service12 } from '../../Icon';
import { ButtonGroup, LogTimeButton, DeleteButton, TimeInputWrapper, TimeInputSeparator } from './TimeTrackerStyles';
import styled from 'styled-components';
import EntityPicker from '../../EntityPicker';
import LayoutPicker from './LayoutPicker';
import { TimeTrackerStyle } from '../../../types';
import { Client, Project, Service } from '../../../types';
import { Entity } from '../../../types';  // Add this import
import Switch from '../../Switch';
import MotionNumber from 'motion-number';

const MinimalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  background: #F0EBE3;
`;

const EditorWrapper = styled.div`
  position: relative;
  z-index: 2;
  .ProseMirror {
    padding: 16px 24px;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 1);
    
    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: rgba(255, 255, 255, 0.5);
      pointer-events: none;
      height: 0;
    }

    p {
      margin: 0;
    }
  }
`;

const RotaryDialWrapperContainer = styled.div`
  position: relative;
  padding-top: 48px;
  z-index: 1;
  height: 0;
  margin: 0 auto;
`;

const RotaryDialWrapper = styled.div`
  position: relative;
  width: 800px; // Increased from 800px
  height: 800px; // Increased from 800px
  margin: 0 auto;
  left: -200px;
`;

const RotaryDial = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #305D50;
  transition: transform 0.1s ease-out;
`;

const DialMarker = styled.div<{ $isHour: boolean }>`
  position: absolute;
  left: 50%;
  width: ${props => props.$isHour ? '1px' : '1px'};
  height: ${props => props.$isHour ? '36px' : '24px'};
  background: rgba(0,0,0,0.3);
  transform-origin: bottom center;
  transition: transform 0.2s ease-out;
`;

const TimeDisplayWrapper = styled(TimeInputWrapper)`
  justify-content: center;
  margin: 80px 0 24px 0;
  position: relative;
  z-index: 2;
`;

const LargeTimeInputField = styled.input`
  font-size: 48px;
  width: 64px;
  text-align: center;
  background: transparent;
  border: none;
  font-family: 'PP Mori';
  font-feature-settings: 'tnum';
  font-weight: bold;
  outline: none;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const LargeTimeInputSeparator = styled(TimeInputSeparator)`
  font-size: 24px;
  font-family: 'PP Mori';
  font-feature-settings: 'tnum';
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
`;

const StyledLogTimeButton = styled(LogTimeButton)`

`;

const StyledDeleteButton = styled(DeleteButton)`
  svg path {
    fill: rgba(255, 255, 255, 0.8);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    svg path {
    fill: rgba(255, 255, 255, 0.8);
    }
  }
`;

const PickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
  z-index: 2;
`;

const PickerLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const PickerRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const BillableToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px 8px;
`;

const LargeTimeInputMotion = styled(MotionNumber)`
  font-size: 48px;
  width: 64px;
  text-align: center;
  background: transparent;
  border: none;
  font-family: 'PP Mori';
  font-weight: bold;
  font-feature-settings: 'tnum';
  outline: none;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: none !important;
  opacity: 1 !important;
`;

const StyledLayoutPicker = styled(LayoutPicker)`
  margin-right: auto;
`;

interface TimeTrackerLayoutProps {
  manualTime: string;
  handleManualTimeChange: (field: 'hours' | 'minutes' | 'seconds', value: string) => void;
  editor: Editor | null;
  isBillable: boolean;
  handleBillableToggle: (checked: boolean) => void;
  isRunning: boolean;
  isPaused: boolean;
  handleStart: () => void;
  handleLogTime: () => void;
  handleDelete: () => void;
  handlePause: () => void;
  elapsedTime: number; // Add this line
  clients: Client[];
  projects: Project[];
  services: Service[];
  selectedClientId: string | null;
  selectedProjectId: string | null;
  selectedServiceId: string | null;
  handleClientChange: (id: string | null) => void;
  handleProjectChange: (id: string | null) => void;
  handleServiceChange: (id: string | null) => void;
  handleResume: () => void;
  timeTrackerStyle: TimeTrackerStyle;
  onLayoutChange: (layout: TimeTrackerStyle) => void;
}

const RadialTimeTracker: React.FC<TimeTrackerLayoutProps> = ({
  manualTime,
  handleManualTimeChange,
  editor,
  isRunning,
  isPaused,
  handleStart,
  handleLogTime,
  handleDelete,
  elapsedTime, // Add this line
  handlePause, // Add this prop
  clients,
  projects,
  services,
  selectedClientId,
  selectedProjectId,
  selectedServiceId,
  handleClientChange,
  handleProjectChange,
  handleServiceChange,
  isBillable,
  handleBillableToggle,
  handleResume,
  timeTrackerStyle,
  onLayoutChange,
}) => {
  const [rotation, setRotation] = useState(0);
  const dialRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isRunning && !isPaused) {
      // Set rotation based on elapsed seconds
      setRotation((elapsedTime % 60) * 6);
      
      const interval = setInterval(() => {
        setRotation((prev) => (prev + 6) % 360);
      }, 1000);
      
      return () => clearInterval(interval);
    }
  }, [isRunning, isPaused, elapsedTime]);

  const renderMarkers = () => {
    const markers = [];
    for (let i = 0; i < 60; i++) {
      const isHour = i % 5 === 0;
      const rotation = i * 6; // 6 degrees per minute
      markers.push(
        <DialMarker
          key={i}
          $isHour={isHour}
          style={{
            transform: `rotate(${rotation}deg) translateX(-50%)`,
            transformOrigin: '50% 400px',
          }}
        />
      );
    }
    return markers;
  };

  // Add these mapping functions
  const mapToEntity = (item: Client | Project | Service): Entity => ({
    id: item.id,
    name: 'name' in item ? item.name : item.full_name || '',
  });

  const clientEntities = clients.map(mapToEntity);
  const projectEntities = projects.map(mapToEntity);
  const serviceEntities = services.map(mapToEntity);

  // Add this renderTimeInput function
  const renderTimeInput = (field: 'hours' | 'minutes' | 'seconds', index: number) => {
    const value = parseInt(manualTime.split(':')[index], 10);
    
    if (isRunning) {
      return (
        <LargeTimeInputMotion
          initial={{ y: 0, opacity: 1 }} // Prevent flying in animation on mount
          animate={{ y: 0, opacity: 1 }} // Ensure it stays in place
          value={value}
          format={{ minimumIntegerDigits: 2 }}
          transition={{
            y: { type: 'spring', duration: 0.5 },
            opacity: { duration: 0.5, ease: 'easeOut', times: [0, 0.3] }
          }}
        />
      );
    } else {
      return (
        <LargeTimeInputField
          type="number"
          value={value.toString().padStart(2, '0')}
          onChange={(e) => handleManualTimeChange(field, e.target.value)}
          min={0}
          max={field === 'hours' ? 23 : 59}
        />
      );
    }
  };

  return (
    <MinimalWrapper>
      <RotaryDialWrapperContainer>
        <RotaryDialWrapper ref={dialRef}>
          <RotaryDial style={{ transform: `rotate(${rotation}deg)` }}>
          {renderMarkers()}
          </RotaryDial>
        </RotaryDialWrapper>
      </RotaryDialWrapperContainer>
      <TimeDisplayWrapper>
        {renderTimeInput('hours', 0)}
        <LargeTimeInputSeparator>:</LargeTimeInputSeparator>
        {renderTimeInput('minutes', 1)}
        <LargeTimeInputSeparator>:</LargeTimeInputSeparator>
        {renderTimeInput('seconds', 2)}
      </TimeDisplayWrapper>
      <PickerContainer>
        <PickerLeft>
          <EntityPicker
            selectedId={selectedClientId}
            onChange={handleClientChange}
            entities={clientEntities}
            label="Client"
            allowUnassigned={true}
            icon={<Client12 />}
            dark={true}
          />
          <EntityPicker
            selectedId={selectedProjectId}
            onChange={handleProjectChange}
            entities={projectEntities}
            label="Project"
            allowUnassigned={true}
            icon={<Project12 />}
            dark={true}
          />
          <EntityPicker
            selectedId={selectedServiceId}
            onChange={handleServiceChange}
            entities={serviceEntities}
            label="Service"
            allowUnassigned={true}
            icon={<Service12 />}
            dark={true}
          />
        </PickerLeft>
        <PickerRight>
          <BillableToggleWrapper>
            <Switch
              checked={isBillable}
              onChange={handleBillableToggle}
              label="Billable"
              sliderColor="#46907B"
              sliderUncheckedColor="rgba(0, 0, 0, 0.2)"
              knobColor="#FFFFFF"
              dark={true}
            />
          </BillableToggleWrapper>
        </PickerRight>
      </PickerContainer>
      <EditorWrapper>
        <EditorContent editor={editor} />
      </EditorWrapper>
      <ButtonGroup>
        {!isRunning ? (
          <StyledLogTimeButton dark={true} onClick={isPaused ? handleResume : handleStart}>
            <Play8/> {isPaused ? 'Resume' : 'Start'}
          </StyledLogTimeButton>
        ) : (
          <StyledLogTimeButton dark={true} onClick={handlePause}>
            <Pause8/> Pause
          </StyledLogTimeButton>
        )}
        {(isRunning || isPaused) && (
          <StyledLogTimeButton dark={true} onClick={handleLogTime}>
            Log Time
          </StyledLogTimeButton>
        )}
        {(isRunning || isPaused) && (
          <StyledDeleteButton dark={true} onClick={handleDelete}><Trash12 /></StyledDeleteButton>
        )}
              <StyledLayoutPicker
        selectedLayout={timeTrackerStyle}
        onLayoutChange={onLayoutChange}
        dark={true}
      />
      </ButtonGroup>
    </MinimalWrapper>
  );
};

export default RadialTimeTracker;
