import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { InvoiceData, Client, BrandSettings } from '../types';
import { useOrganization } from './useOrganization';

export const useInvoice = (id: string | undefined) => {
  const { data: organizationId } = useOrganization();

  return useQuery<InvoiceData>({
    queryKey: ['invoice', id, organizationId],
    queryFn: async () => {
      if (!id || !organizationId) throw new Error('Invoice ID or Organization ID is required');

      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .select(`
          *,
          invoice_items(*),
          payments(*),
          organization:organizations(id, business_name, logo_url)
        `)
        .eq('id', id)
        .single();

      if (invoiceError) throw invoiceError;

      return {
        ...invoiceData,
        items: invoiceData.invoice_items || [],
        payments: invoiceData.payments || [],
        business_name: invoiceData.organization?.business_name,
        logo_url: invoiceData.organization?.logo_url,
      };
    },
    enabled: !!id && !!organizationId,
    staleTime: 5 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
};

export const useClients = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<Client[]>({
    queryKey: ['clients', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization ID is required');
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });
};

export const useBrandSettings = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<BrandSettings>({
    queryKey: ['brandSettings', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization not found');

      const { data, error } = await supabase
        .from('brand_settings')
        .select('*')
        .eq('organization_id', organizationId)
        .single();

      if (error) throw error;
      return data || {
        default_template: 'simple',
        default_font: 'Arial',
        default_header_color: '#ffffff',
        default_header_text_color: '#000000',
        default_background_color: '#ffffff',
        default_body_text_color: '#000000',
      };
    },
    enabled: !!organizationId,
  });
};