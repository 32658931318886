import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import styled from 'styled-components';

// Add this utility function at the top of the file
function getContrastColor(backgroundColor: string): string {
  const hex = backgroundColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)';
}

interface NotesEditorProps {
  initialContent: string;
  onUpdate: (content: string) => void;
  backgroundColor?: string;
}

const NotesEditor: React.FC<NotesEditorProps> = ({ initialContent, onUpdate, backgroundColor }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Start typing your notes here...',
      }),
    ],
    content: initialContent,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onUpdate(html);
    },
  });

  return (
    <EditorWrapper $backgroundColor={backgroundColor}>
      <EditorContent editor={editor} />
    </EditorWrapper>
  );
};

const EditorWrapper = styled.div<{ $backgroundColor?: string }>`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 32px 76px 80px 76px;
    border: transparent;
    border-radius: 0;
    background-color: ${props => props.$backgroundColor || 'transparent'};

    p.is-editor-empty:first-child::before {
      color: ${props => props.$backgroundColor ? getContrastColor(props.$backgroundColor) : 'rgba(0, 0, 0, 0.5)'};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

export default NotesEditor;