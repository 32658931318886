import React from "react";
import { useNavigate, useMatch, Link } from '@tanstack/react-router';
import styled from "styled-components";
import { useOrganization } from "./hooks/useOrganization";
import Button from "./components/Button";
import PageSkeleton from "./components/PageSkeleton";
import Tabs from "./components/Tabs";
import { usePageContext } from "./hooks/usePageContext";
import { Outlet } from '@tanstack/react-router';

const PageContainer = styled.div``;

const MainContent = styled.div`
  padding: 20px 48px;
`;

const SettingsPage: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const navigate = useNavigate();
  const {
    data: organizationId,
    isLoading,
    error,
  } = useOrganization();

  // Update the useMatch hook to use the correct path
  const match = useMatch({ from: '/auth/settings' });

  React.useEffect(() => {
    setPageHeaderProps({
      title: "Settings",
    });
  }, [setPageHeaderProps]);

  if (isLoading) {
    return <PageSkeleton title="Settings" />;
  }

  if (error) {
    return <PageContainer>Error: {error.message}</PageContainer>;
  }

  if (!organizationId) {
    return (
      <PageContainer>
        <MainContent>
          <p>
            Please select an organization to manage its settings or create a new
            one.
          </p>
          <Button onClick={() => navigate({ to: "/settings" })}>
            Select Organization
          </Button>
        </MainContent>
      </PageContainer>
    );
  }

  const activeTab = match ? match.pathname.split('/').pop() || 'profile' : 'profile';

  const tabs = [
    { id: "profile", label: "Profile", to: "/settings/profile" },
    { id: "organization", label: "Organization", to: "/settings/organization" },
    { id: "users", label: "Users", to: "/settings/users" },
    { id: "services", label: "Services", to: "/settings/services" },
    { id: "payments", label: "Payments", to: "/settings/payments" },
    { id: "task-statuses", label: "Task Statuses", to: "/settings/task-statuses" },
  ];

  return (
    <PageContainer>
      <Tabs
        tabs={tabs.map(tab => ({
          ...tab,
          content: <Link to={tab.to}>{tab.label}</Link>
        }))}
        activeTab={activeTab}
        onTabChange={(tabId) => navigate({ to: `/settings/${tabId}` })}
      />
      <MainContent>
        <Outlet />
      </MainContent>
    </PageContainer>
  );
};

export default SettingsPage;