import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { Client, Project, Service, TimeTrackerStyle } from '../../types';
import { Expand12 } from '../Icon';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import DefaultTimeTracker from './TimeTrackerLayouts/DefaultTimeTracker';
import ModernTimeTracker from './TimeTrackerLayouts/ModernTimeTracker';
import RadialTimeTracker from './TimeTrackerLayouts/RadialTimeTracker';
import EveningTimeTracker from './TimeTrackerLayouts/EveningTimeTracker';
import { useQueryClient } from '@tanstack/react-query';
import { useOrganization } from '../../hooks/useOrganization';

const TimeTrackerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledExpandIcon = styled(Expand12)`
  width: 10px;
  height: 10px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 1.5px;

  path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const AnimatedTimeDisplay = styled.div`
  display: inline-flex;
  overflow: hidden;
  height: 1em;
  line-height: 1em;
  justify-content: center;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 4px 4px 4px 4px;
  border-radius: 6px;
  color: inherit;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);

    ${StyledExpandIcon} {
      path {
        fill: rgba(0, 0, 0, 0.8);
      }
    }
  }
`;

const AnimatedDigit = styled.div`
  display: inline-block;
  height: 1em;
  transition: transform 0.3s ease-out;
  text-align: center;
`;

const DigitWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnimatedContainer = styled.div`
  display: inline-flex;
  overflow: hidden;
  height: 1.1em;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: top right;
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 
    0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05),
    0 32px 32px rgba(0, 0, 0, 0.05),
    0 0 0 0.5px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 0px;
  width: 400px;
`;

interface TimeTrackerPopoverProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isRunning: boolean;
  isPaused: boolean;
  elapsedTime: number;
  startTimer: (clientId: string | null, projectId: string | null, serviceId: string | null, description: string, initialElapsedTime?: number, isBillable?: boolean) => void;
  stopTimer: () => void;
  updateTimer: (clientId: string | null, projectId: string | null, serviceId: string | null, description: string, manualSeconds?: number, newIsBillable?: boolean) => void;
  cancelTimer: () => void;
  deleteTimeEntry: () => Promise<void>;
  pauseTimer: () => void;
  resumeTimer: () => void;
  clients: Client[];
  projects: Project[];
  services: Service[];
  description: string;
  setDescription: (description: string) => void;
  isBillable: boolean;
  setIsBillable: (isBillable: boolean) => void;
  currentTimeEntryId: string | null;
}

const TimeTrackerPopover: React.FC<TimeTrackerPopoverProps> = ({
  isOpen,
  setIsOpen,
  isRunning,
  isPaused,
  elapsedTime,
  startTimer,
  stopTimer,
  updateTimer,
  cancelTimer,
  deleteTimeEntry,
  pauseTimer,
  resumeTimer,
  clients,
  projects,
  services,
  description,
  setDescription,
  isBillable,
  setIsBillable,
  currentTimeEntryId,
}) => {
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
  const [manualTime, setManualTime] = useState("00:00:00");
  const [localElapsedTime, setLocalElapsedTime] = useState(elapsedTime);
  const [isMounted, setIsMounted] = useState(false);
  const [localDescription, setLocalDescription] = useState(description);
  const [timeTrackerStyle, setTimeTrackerStyle] = useState<TimeTrackerStyle>(() => {
    const savedStyle = localStorage.getItem('timeTrackerStyle') as TimeTrackerStyle | null;
    return savedStyle || 'default';
  });

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top";
      case "left":
      case "left-start":
      case "left-end":
        return "right";
      case "right":
      case "right-start":
      case "right-end":
        return "left";
      default:
        return "top";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const click = useClick(context, {
    toggle: true,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  useEffect(() => {
    setLocalElapsedTime(elapsedTime);
  }, [elapsedTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRunning && !isPaused) {
      interval = setInterval(() => {
        setLocalElapsedTime(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, isPaused]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRunning && !isPaused) {
      interval = setInterval(() => {
        updateTimer(selectedClientId, selectedProjectId, selectedServiceId, description, localElapsedTime);
      }, 5000); // Update every 5 seconds
    }
    return () => clearInterval(interval);
  }, [isRunning, isPaused, updateTimer, selectedClientId, selectedProjectId, selectedServiceId, description, localElapsedTime]);

  useEffect(() => {
    const hours = Math.floor(localElapsedTime / 3600);
    const minutes = Math.floor((localElapsedTime % 3600) / 60);
    const seconds = localElapsedTime % 60;
    setManualTime(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
  }, [localElapsedTime]);

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => setIsMounted(false), 150); // Delay unmounting
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  const handleClientChange = async (newClientId: string | null) => {
    setSelectedClientId(newClientId);
    if (isRunning) {
      await updateTimer(newClientId, selectedProjectId, selectedServiceId, description);
    }
  };

  const handleProjectChange = async (newProjectId: string | null) => {
    setSelectedProjectId(newProjectId);
    if (isRunning) {
      await updateTimer(selectedClientId, newProjectId, selectedServiceId, description);
    }
  };

  const handleServiceChange = async (newServiceId: string | null) => {
    setSelectedServiceId(newServiceId);
    if (isRunning && newServiceId) {
      const selectedService = services.find(service => service.id === newServiceId);
      if (selectedService) {
        await updateTimer(selectedClientId, selectedProjectId, newServiceId, selectedService.name);
        setDescription(selectedService.name);
      }
    }
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Description...',
      }),
    ],
    content: localDescription,
    onUpdate: ({ editor }) => {
      const newDescription = editor.getText();
      setLocalDescription(newDescription);
      // Remove the updateTimer call here
    },
  });

  useEffect(() => {
    if (editor && localDescription !== editor.getText()) {
      editor.commands.setContent(localDescription);
    }
  }, [editor, localDescription]);

  const handleManualTimeChange = (field: 'hours' | 'minutes' | 'seconds', value: string) => {
    const numValue = parseInt(value, 10);
    const [hours, minutes, seconds] = manualTime.split(':').map(Number);
    
    let newHours = hours;
    let newMinutes = minutes;
    let newSeconds = seconds;

    switch (field) {
      case 'hours':
        newHours = isNaN(numValue) ? 0 : Math.min(Math.max(numValue, 0), 23);
        break;
      case 'minutes':
      case 'seconds':
        {
          const newValue = isNaN(numValue) ? 0 : Math.min(Math.max(numValue, 0), 59);
          if (field === 'minutes') newMinutes = newValue;
          else newSeconds = newValue;
        }
        break;
    }

    const newManualTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}:${newSeconds.toString().padStart(2, '0')}`;
    setManualTime(newManualTime);
    
    const totalSeconds = newHours * 3600 + newMinutes * 60 + newSeconds;
    setLocalElapsedTime(totalSeconds);
    updateTimer(selectedClientId, selectedProjectId, selectedServiceId, description, totalSeconds);
  };

  const handleBillableToggle = (newIsBillable: boolean) => {
    setIsBillable(newIsBillable);
    if (isRunning) {
      updateTimer(selectedClientId, selectedProjectId, selectedServiceId, description, undefined, newIsBillable);
    }
  };

  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  const handleStart = () => {
    if (isPaused && currentTimeEntryId) {
      // Resume the paused timer
      resumeTimer();
    } else {
      // Start a new timer
      const [hours, minutes, seconds] = manualTime.split(':').map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      startTimer(selectedClientId, selectedProjectId, selectedServiceId, localDescription, totalSeconds, isBillable);
    }
    setIsOpen(false);
    
    if (organizationId) {
      queryClient.invalidateQueries({ queryKey: ['timeEntries', organizationId] });
    }
  };

  const handlePause = () => {
    pauseTimer();
  };

  const handleResume = () => {
    resumeTimer();
  };

  const handleLogTime = () => {
    updateTimer(selectedClientId, selectedProjectId, selectedServiceId, localDescription);
    setDescription(localDescription);
    stopTimer();
    setIsOpen(false);
  };

  const handleDelete = async () => {
    await deleteTimeEntry();
    cancelTimer();
    setIsOpen(false);
  };

  const renderAnimatedTime = (time: number) => {
    try {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = time % 60;
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      return formattedTime.split("").map((digit, index) => (
        <AnimatedContainer key={index} style={{ width: [":"].includes(digit) ? 'auto' : '1ch' }}>
          {[":"].includes(digit) ? (
            <div>{digit}</div>
          ) : (
            <AnimatedDigit
              style={{
                transform: `translateY(calc(${-Number(digit)} * 1em))`,
                width: '1ch',
              }}
            >
              <DigitWrapper>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => (
                  <div key={n} style={{ height: "1em" }}>{n}</div>
                ))}
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => (
                  <div key={`${n}-duplicate`} style={{ height: "1em" }}>{n}</div>
                ))}
              </DigitWrapper>
            </AnimatedDigit>
          )}
        </AnimatedContainer>
      ));
    } catch (error) {
      console.error('Error rendering animated time:', error);
      return '00:00:00'.split('').map((digit, index) => (
        <AnimatedContainer key={index} style={{ width: [":"].includes(digit) ? 'auto' : '1ch' }}>
          <div>{digit}</div>
        </AnimatedContainer>
      ));
    }
  };

  const handleLayoutChange = (newLayout: TimeTrackerStyle) => {
    setTimeTrackerStyle(newLayout);
    localStorage.setItem('timeTrackerStyle', newLayout);
  };

  const renderTimeTracker = () => {
    const commonProps = {
      selectedClientId,
      selectedProjectId,
      selectedServiceId,
      handleClientChange,
      handleProjectChange,
      handleServiceChange,
      clients,
      projects,
      services,
      manualTime,
      handleManualTimeChange,
      editor,
      isBillable,
      handleBillableToggle,
      isRunning,
      isPaused,
      handleStart,
      handlePause,
      handleResume,
      handleLogTime,
      handleDelete,
      elapsedTime: localElapsedTime,
      timeTrackerStyle,
      onLayoutChange: handleLayoutChange,
    };

    switch (timeTrackerStyle) {
      case 'modern':
        return <ModernTimeTracker {...commonProps} />;
      case 'radial':
        return <RadialTimeTracker {...commonProps} />;
      case 'evening':
        return <EveningTimeTracker {...commonProps} />;
      default:
        return <DefaultTimeTracker {...commonProps} />;
    }
  };

  return (
    <TimeTrackerWrapper>
      <AnimatedTimeDisplay
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {renderAnimatedTime(localElapsedTime)}
        <StyledExpandIcon />
      </AnimatedTimeDisplay>
      <FloatingPortal>
        {isMounted && (
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 2000,
            }}
            {...getFloatingProps()}
          >
            <PopoverContent style={styles} $transformOrigin={transformOrigin}>
              {renderTimeTracker()}
            </PopoverContent>
          </div>
        )}
      </FloatingPortal>
    
    </TimeTrackerWrapper>
  );
};

export default TimeTrackerPopover;