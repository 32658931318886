import React from 'react';
import styled, { css } from 'styled-components';
import { calculateInvoiceTotals } from '../../utils/invoiceCalculations';
import { InvoiceData } from '../../types';

const mobile = (inner: TemplateStringsArray) => css`
  @media (max-width: 768px) {
    ${inner}
  }
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px;
`;

const InvoiceTotal = styled.div`
  width: fit-content;
  min-width: 336px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${mobile`
    margin-top: 32px;
    min-width: 100%;
  `}
`;

const Divider = styled.div<{ $bodyTextColor: string }>`
  width: 100%;
  height: 1px;
  background-color: ${props => props.$bodyTextColor};
  margin: 8px 0;
  opacity: 0.2;
`;

const TotalItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface InvoiceTotalsProps {
  invoice: InvoiceData;
  bodyTextColor: string;
}

const InvoiceTotals: React.FC<InvoiceTotalsProps> = ({ invoice, bodyTextColor }) => {
  const totals = calculateInvoiceTotals(invoice);

  return (
    <TotalContainer>
      <InvoiceTotal>
        <TotalItem><span>Subtotal:</span> <span>${totals.subtotal.toFixed(2)}</span></TotalItem>
        <TotalItem><span>Tax ({invoice.tax_rate || 0}%):</span> <span>${totals.taxAmount.toFixed(2)}</span></TotalItem>
        <TotalItem><span>Total:</span> <span>${totals.total.toFixed(2)}</span></TotalItem>
        <TotalItem><span>Total Paid:</span> <span>${totals.totalPaid.toFixed(2)}</span></TotalItem>
        <Divider $bodyTextColor={bodyTextColor} />
        <TotalItem><span>Amount Due:</span> <span>${totals.amountDue.toFixed(2)}</span></TotalItem>
      </InvoiceTotal>
    </TotalContainer>
  );
};

export default InvoiceTotals;